import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { cognitoPaths } from "./paths";
import { Button } from "../../../../components/form/button";
import { NavbarRoute } from "../../../../components/header";
import { LanguageSelector } from "../../../../components/language-selector";
import { Text } from "../../../../components/text";
import { useT } from "../../../../i18n/use-t";
import {
  lastActiveTenantLocalStorageKey,
  lastActiveTenantSessionStorageKey,
} from "../../../../navigate-to-last-active-tenant";
import { useTenantConfig } from "../../../../tenant-settings";
import { useDimensions } from "../../../../util/hooks";
import {
  RouteWithTitle,
  useMetadataForActiveRoute,
} from "../../../../util/metadata-for-active-route";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import { useCognitoAutoSignIn } from "../hooks/use-cognito-auto-sign-in";

const ROOT = "/";

export type CognitoRouteMetadataProps = {
  routes: RouteWithTitle[];
};

export const CognitoLayout: React.FC<CognitoRouteMetadataProps> = ({
  routes,
}) => {
  const settings = useTenantConfig();
  useCognitoAutoSignIn();

  const { message: { level, ...message } = {} } = useAuthNavigateState();

  return (
    <AuthWrapper routes={routes} isFlat={true}>
      <div className="flex min-h-[calc(100vh-4rem)] w-full items-center justify-center overflow-y-auto">
        <div className="my-6 flex max-w-[calc(100%-4rem)] flex-col gap-4">
          <img
            className="h-20 self-center object-contain object-bottom"
            src={settings?.authLogo}
          />
          <div
            className={`w-full rounded p-1 ${
              level === AuthMessageLevel.ERROR ? "text-error" : ""
            }`}
          >
            <Text {...message} />
          </div>
          <Outlet />
        </div>
      </div>
    </AuthWrapper>
  );
};

export type AuthWrapperProps = {
  isFlat?: boolean;
} & CognitoRouteMetadataProps;

export const AuthWrapper: React.FC<PropsWithChildren<AuthWrapperProps>> = ({
  children,
  routes,
  isFlat,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useTenantId();
  const { pathname } = useLocation();
  const t = useT();

  const navbarRef = useRef<HTMLDivElement>(null);
  const navbarDimension = useDimensions(navbarRef);

  const canNavigateToRoot = useMemo(
    () => new URLSearchParams(location.search).toString() === "",
    [location.search],
  );

  const removeLastActiveTenant = useCallback(() => {
    localStorage.removeItem(lastActiveTenantLocalStorageKey);
    sessionStorage.removeItem(lastActiveTenantSessionStorageKey);
  }, []);

  const isOrganisationSignUp = useMemo(
    () =>
      location.pathname.includes(cognitoPaths.signUp) && tenantId.organisation,
    [location.pathname, tenantId.organisation],
  );

  useEffect(() => {
    if (isOrganisationSignUp) {
      navigate(cognitoPaths.signIn);
    }
  }, [isOrganisationSignUp, navigate]);

  useMetadataForActiveRoute(
    useMemo(
      () =>
        routes.map(
          (route): NavbarRoute => ({
            path: route.path,
            position: "start",
            title: t(route.title),
            isActive: !!matchPath(pathname, route.path),
          }),
        ),
      [pathname, routes, t],
    ),
  );

  return (
    <>
      <div
        ref={navbarRef}
        className={`navbar fixed z-[1001] flex flex-row justify-between bg-white p-4 ${
          isFlat ? "" : "shadow-xl"
        }`}
      >
        {canNavigateToRoot ? (
          <Button
            onClick={() => {
              removeLastActiveTenant();
              navigate(ROOT);
            }}
            icon="left"
            text="Disease selection"
          />
        ) : (
          <div />
        )}
        <LanguageSelector className="max-w-[120px] self-end" />
      </div>
      {!isFlat ? (
        <>
          <div
            style={{ paddingBottom: navbarDimension.height }}
            className="w-full"
          />
          <div style={{ height: `calc(100vh - ${navbarDimension.height})` }}>
            {children}
          </div>
        </>
      ) : (
        children
      )}
    </>
  );
};
