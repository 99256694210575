import { confirmSignIn, signIn, signUp } from "aws-amplify/auth";
import { StringMap } from "shared/types/cognito-client-metadata";

import {
  generatePassword,
  SignInResponse,
  SignInStatus,
  SignUpResponse,
  SignUpStatus,
} from "../routes/cognito-magic-link-sign-up";

export interface CognitoAuthInput {
  email: string;
  name: string;
  clientMetadata: StringMap;
}

export const signUserIn = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignInResponse> => {
  try {
    const username = email;
    const { isSignedIn, nextStep } = await signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (
      !isSignedIn &&
      nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
    ) {
      const { isSignedIn: configmSignedIn, nextStep: confirmNextStep } =
        await confirmSignIn({
          challengeResponse: "__dummy__",
          options: { clientMetadata },
        });

      if (
        !configmSignedIn &&
        confirmNextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
      ) {
        return { status: SignInStatus.MagicLinkSent };
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      if (err.name === "UserNotFoundException") {
        return { status: SignInStatus.UserNotFound, error: err };
      }
      return { status: SignInStatus.UnknownError, error: err };
    }
    return { status: SignInStatus.UnknownError };
  }
  return { status: SignInStatus.UnknownError };
};

export const signUserUp = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignUpResponse> => {
  const password = generatePassword();

  try {
    const { isSignUpComplete, nextStep, userId } = await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (!isSignUpComplete || nextStep.signUpStep !== "DONE") {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error(
          "Unexpected state (" +
            isSignUpComplete +
            ", " +
            nextStep.signUpStep +
            ") after sign up",
        ),
      };
    }

    if (!userId) {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error("User ID is missing after sign up"),
      };
    }

    const signInStatus = await signUserIn({
      email,
      name,
      clientMetadata,
    });

    switch (signInStatus.status) {
      case SignInStatus.MagicLinkSent:
        return { status: SignUpStatus.MagicLinkSent };
      case SignInStatus.UserNotFound:
        return {
          status: SignUpStatus.UnknownError,
          error: new Error(
            "Did not expect the user to not be found at this stage",
          ),
        };
      case SignInStatus.UnknownError:
        return {
          status: SignUpStatus.UnknownError,
          error: signInStatus.error,
        };
    }
  } catch (err) {
    if (err instanceof Error) {
      return { status: SignUpStatus.UnknownError, error: err };
    }

    return { status: SignUpStatus.UnknownError };
  }
};
