import { FC } from "react";

import { UpdateSettingsDto } from "../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { Checkbox } from "../../components/form/checkbox";
import { queryClient } from "../../query-client";
import { ME_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const ProfileVisibilityCheckbox: FC = () => {
  const tenantId = useTenantId();

  const { mutate: updateSettings } = useApiMutation(
    "backend",
    (api) => (updateSettingsDto: UpdateSettingsDto) =>
      api.updateSettings({
        updateSettingsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    {
      successMessage: {
        tx: "profile.updateSuccessful",
      },
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  return (
    <Checkbox
      className="font-bold"
      label={{ tx: "community.agreedToBeingListedLabel" }}
      onChange={() => {
        updateSettings({ agreedToBeingListed: true });
      }}
    />
  );
};
