import { FC } from "react";
import { twMerge } from "tailwind-merge";

import { DeleteUserButton } from "./delete-user-button";
import { ChangePasswordButton } from "../../auth/implementations/cognito/components/change-password-button";
import { Text } from "../../components/text";
import { useModal } from "../../models/modal-provider";

export const ManageAccountModal: FC<JSX.IntrinsicElements["div"]> = ({
  className,
}) => {
  const { showModal } = useModal();

  return (
    <Text
      className={twMerge("cursor-pointer text-black", className)}
      tx="profile.manageAccount"
      onClick={() => {
        showModal({
          title: { tx: "profile.manageAccount" },
          children: (
            <div className="flex w-full flex-col gap-4">
              <ChangePasswordButton />
              <DeleteUserButton />
            </div>
          ),
        });
      }}
    />
  );
};
