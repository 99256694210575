import { EventModifier } from "./types";
import { EventRecord } from "../../api/generated/multiagent";
import { TextInput } from "../form/text-input";

export const EditableEventText: React.FC<{
  event: EventRecord;
  editEvent: (modifier: EventModifier) => void;
}> = ({ event, editEvent }) => {
  return (
    <TextInput
      as="textarea"
      className="max-h-40"
      value={event.text}
      onChange={(e) =>
        editEvent((editableEvent) => (editableEvent.text = e.target.value))
      }
    />
  );
};
