import { isEmailRegex } from "../../../../../util/regex";
import { ValidationFunction } from "../lookup";

export const emailValidation: ValidationFunction = (value) => {
  if (!isEmailRegex.test(String(value))) {
    return {
      ok: false,
      message: { tx: "auth.signUp.inputs.email.notAnEmailError" },
    };
  }

  return {
    ok: true,
  };
};
