import { useState } from "react";
import PhoneInput from "react-phone-number-input";

import { BasicWithButton } from "./fragments/basic-with-button";
import { ChatOptionComponentProps } from "../lookup";

export const PhoneTextInput: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <BasicWithButton sendResponse={sendResponse} message={phoneNumber}>
      <PhoneInput
        className="input-bordered input"
        onChange={(phone) => setPhoneNumber(phone ? phone : "")}
      />
    </BasicWithButton>
  );
};
