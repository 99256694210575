import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { cognitoPaths } from "./paths";
import { PlainError } from "../../../../components/events/plain-error";
import { LoadingSpinner } from "../../../../components/loading-spinner";
import { useStore } from "../../../../models/helpers";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";
import { returnLocalizedCognitoError } from "../misc/cognito-errors";

function assertIsMessage(
  msg: unknown,
): asserts msg is { userName: string; exp: number; iat: number } {
  if (
    !msg ||
    typeof msg !== "object" ||
    !("userName" in msg) ||
    typeof msg.userName !== "string" ||
    !("exp" in msg) ||
    typeof msg.exp !== "number" ||
    !("iat" in msg) ||
    typeof msg.iat !== "number"
  ) {
    throw new Error("Invalid magic link");
  }
}

const decodeMagicToken = (token: string) => {
  try {
    const header = token.split(".")[0];
    const message = JSON.parse(atob(header));

    assertIsMessage(message);

    return message.userName;
  } catch (err) {
    console.log("Error when decoding magic token: " + err);
    throw err;
  }
};

export const CognitoVerifyMagicLink: React.FC = () => {
  const store = useStore();
  const [params] = useSearchParams();
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();
  const cognitoConfigurationState = useCognitoConfiguration();

  const clientMetadata: CognitoClientMetadata & { [key: string]: string } =
    useMemo(
      () => ({
        disease,
        ...(organisation ? { organisation } : {}),
        isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
        signInMethod: "MAGIC_LINK",
        authFlowType: "CUSTOM_WITHOUT_SRP",
        alreadyHaveMagicLink: "yes",
        language,
      }),
      [disease, organisation, language],
    );

  const navigate = useAuthNavigate();

  useEffect(() => {
    if (cognitoConfigurationState !== "ready") {
      return;
    }
    const token = params.get("token");

    if (!token) {
      store.addToastEvent(
        new PlainError({ tx: "auth.signInMagic.verifyMagicLinkError" }),
      );
      return;
    }

    const dataToSend = {
      challengeResponse: token,
      options: {
        clientMetadata,
      },
    };

    const username = decodeMagicToken(dataToSend.challengeResponse);

    signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        autoSignIn: false,
        clientMetadata,
      },
    })
      .then(({ isSignedIn, nextStep }) => {
        if (
          !isSignedIn &&
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          confirmSignIn(dataToSend)
            .then(
              ({ isSignedIn: localIsSignedIn, nextStep: localNextStep }) => {
                if (localIsSignedIn) {
                  navigate({
                    to: {
                      type: "redirect_url",
                    },
                    replace: true,
                  });
                } else {
                  console.log("confirmSignIn", localNextStep);
                }
              },
            )
            .catch((err) => {
              console.log("confirmSignIn", err);
              navigate({
                to: {
                  type: "uri",
                  uri: cognitoPaths.loginProblem,
                },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.ERROR,
                    tx: "auth.loginProblem.outdatedLink",
                  },
                },
              });
            });
        }
      })
      .catch((err) => {
        console.log("signIn", err);
        navigate({
          to: {
            type: "current_url",
          },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              ...returnLocalizedCognitoError(err.code, err.message),
            },
          },
        });
      });
  }, [clientMetadata, cognitoConfigurationState, navigate, params, store]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingSpinner
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
