import React, { PropsWithChildren, useEffect, useMemo } from "react";

import { toTenantIdHeader, useApiQuery } from "../../../api/use-api";
import { CHAT_COMPLETENESS_KEY } from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { Text } from "../../text";

export const ChatCompletion: React.FC<
  PropsWithChildren<{
    conversationId?: string;
  }>
> = ({ children, conversationId }) => {
  const tenantId = useTenantId();

  const { data: chatCompletenessPercentage } = useApiQuery(
    "multiagent",
    (api) =>
      api.getMultiagentChatCompleteness({
        ...toTenantIdHeader(tenantId),
        conversationId: conversationId as string,
      }),
    CHAT_COMPLETENESS_KEY(tenantId.disease),
    undefined,
    { enabled: !!conversationId },
  );

  useEffect(() => {
    console.log("ChatCompletion", chatCompletenessPercentage);
  }, [chatCompletenessPercentage]);

  const LinearProgress = useMemo(
    () => (
      <div className="flex w-full flex-row items-center gap-4 md:hidden">
        <progress
          className="progress-primary progress"
          value={chatCompletenessPercentage ?? 0}
          max="100"
        />
        <Text
          className="text-sm"
          text={`${chatCompletenessPercentage ?? 0}%`}
        />
      </div>
    ),
    [chatCompletenessPercentage],
  );

  const CircularProgress = useMemo(
    () => (
      <div className="hidden h-full flex-col items-center justify-center gap-1 md:flex">
        <div
          className="radial-progress m-auto w-full items-center text-primary"
          style={
            {
              width: 50,
              height: 50,
              "--thickness": "1px",
              "--value": chatCompletenessPercentage ?? 0,
            } as object
          }
          role="progressbar"
        >
          {`${chatCompletenessPercentage}%`}
        </div>
      </div>
    ),
    [chatCompletenessPercentage],
  );

  return (
    <div className="item flex w-full max-w-[1660px] flex-col gap-4 px-2 md:flex-row">
      {LinearProgress}
      {CircularProgress}
      <div className="flex w-full flex-row justify-center gap-2">
        {children}
      </div>
    </div>
  );
};
