import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NotificationFrequency } from "shared/types/notification-frequency";
import { NotificationType as NotificationTypeOptions } from "shared/types/notification-type";

import {
  CreateNotificationRequest,
  DeleteNotificationRequest,
  Notification as NotificationType,
} from "../api/generated/backend";
import { useApiMutation, useApiQuery } from "../api/use-api";
import { PlainError } from "../components/events/plain-error";
import { GhostButton } from "../components/form/button";
import { Dropdown, DropdownProps } from "../components/form/dropdown";
import { Form } from "../components/form/form";
import { FormTelephoneInput } from "../components/form/form-telephone-input";
import { FormTextInput } from "../components/form/form-text-input";
import { LoadingButton } from "../components/form/loading-button";
import { SvgIcon } from "../components/icons/svg-icon";
import { telephone } from "../components/icons/svgs";
import { Text, tx } from "../components/text";
import {
  TimePicker,
  TimePickerProps,
} from "../components/time-picker/time-picker";
import { Tooltip } from "../components/tooltip";
import { useProfile } from "../data-collection/profile/profile";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useStore } from "../models/helpers";
import { useModal } from "../models/modal-provider";
import { NOTIFICATION_KEY } from "../types/query-keys";
import { formatDate } from "../util/date";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

const notificationFrequencyOptions: DropdownProps<NotificationFrequency>["options"] =
  Object.values(NotificationFrequency).map((value) => ({
    value,
    label: { tx: `notifications.frequency.${value}` },
  }));

const notificationTypeOtions: DropdownProps<NotificationTypeOptions>["options"] =
  [
    {
      label: { tx: "notifications.medication" },
      value: NotificationTypeOptions.RECURRING,
    },
    {
      label: { tx: "notifications.appointment" },
      value: NotificationTypeOptions.NON_RECURRING,
    },
  ];

export const Notifications: React.FC = () => {
  return (
    <DefaultContentContainer>
      <NotificationsExplanation />
      <ScheduleNotificationForm />
      <ActiveNotificationsList />
    </DefaultContentContainer>
  );
};

const NotificationsExplanation: React.FC = () => {
  return (
    <div className="mb-5 rounded-lg bg-chat-bubble p-4 text-black">
      <Text tx="notifications.notificationsExplanation" />
    </div>
  );
};

const ActiveNotificationsList: React.FC = () => {
  const { disease } = useTenantId();
  const { data: notifications } = useApiQuery(
    "backend",
    (api) => api.getNotifications(),
    NOTIFICATION_KEY(disease),
  );

  return (
    <div className="mt-5 flex flex-col gap-3">
      <Text className="text-2xl" tx="notifications.scheduledNotifications" />
      {notifications?.length ? (
        notifications.map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))
      ) : (
        <Text
          className="mt-5 self-center"
          tx="notifications.noScheduledNotifications"
        />
      )}
    </div>
  );
};

const Notification: React.FC<NotificationType> = ({
  id,
  title,
  sendAt,
  message,
  hasError,
  frequency,
}) => {
  const { showModal, hideModal } = useModal();
  const queryClient = useQueryClient();
  const { disease } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  const { mutate: deleteNotification } = useApiMutation(
    "backend",
    (api) => (request: DeleteNotificationRequest) =>
      api.deleteNotification(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATION_KEY(disease));
      },
      onSettled: hideModal,
    },
  );

  return (
    <div
      className={`card flex flex-col  bg-base-100  p-5 shadow-xl ${
        hasError ? "border-2 border-error" : ""
      }`}
    >
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <Text className="text-xl font-bold" text={title} />
          {"|"}
          <Text
            className="text-primary"
            tx={
              notificationFrequencyOptions.find(
                (option) => option.value === frequency,
              )?.label.tx
            }
          />
        </div>
        <GhostButton
          icon="cross"
          onClick={() => {
            showModal({
              title: { tx: "notifications.deleteNotification" },
              description: { tx: "notifications.deleteNotificationWarning" },
              onConfirm: () => {
                deleteNotification({ id });
              },
            });
          }}
        />
      </div>
      {hasError ? (
        <Text
          className="text-error"
          tx="notifications.errors.sendingNotification"
        />
      ) : (
        <div className="-mt-2 flex flex-col gap-2">
          <Text
            className="mb-2 text-gray-400"
            tx="notifications.nextNotificationAt"
            txData={{
              arrivalDate: new Date(sendAt).toLocaleDateString(language, {
                weekday: "long",
                month: "numeric",
                year: "numeric",
                minute: "numeric",
                hour: "numeric",
              }),
            }}
          />
          {message}
        </div>
      )}
    </div>
  );
};

const ScheduleNotificationForm: React.FC = () => {
  const { showProfile } = useProfile();
  const store = useStore();

  const [notificationType, setNotificaitonType] = useState(
    NotificationTypeOptions.RECURRING,
  );
  const [frequency, setFrequency] = useState(
    notificationFrequencyOptions.find(
      (opt) => opt.value === NotificationFrequency.EVERY_TWENTYFOUR_HOURS,
    )?.value,
  );

  const [date, setDate] = useState(new Date());

  const [sendFromTime, setSendFromTime] = useState({
    hours: new Date().getHours(),
    minutes: new Date().getMinutes(),
  });

  const queryClient = useQueryClient();
  const { disease } = useTenantId();
  const user = useUser();
  const {
    i18n: { language },
  } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<{
    title: string;
    message: string;
    telephone: string;
    messageTitle: string;
  }>();

  useEffect(() => {
    resetField("telephone", {
      defaultValue: user?.userAttributes?.telephone ?? undefined,
    });
  }, [resetField, user?.userAttributes?.telephone]);

  const { mutate: createNotification } = useApiMutation(
    "backend",
    (api) => (request: CreateNotificationRequest) =>
      api.createNotification(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATION_KEY(disease));
      },
    },
  );

  const handleFormSubmit = handleSubmit(({ message, title, messageTitle }) => {
    if (!telephone) {
      return store.addToastEvent(
        new PlainError({ tx: "notifications.errors.phoneNumberEmpty" }),
      );
    }
    if (date && frequency) {
      createNotification({
        notificationDto: {
          title,
          message,
          language,
          frequency,
          messageTitle,
          type: notificationType,
          sendAt: copyDateWithNewTime(date, sendFromTime),
        },
      });
    }
    reset({ telephone: user?.userAttributes?.telephone ?? undefined });
  });

  const resetDate = useCallback(() => {
    setDate(new Date());
  }, []);

  useEffect(() => {
    if (date === null) {
      resetDate();
    }
  }, [date, resetDate]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="flex w-full flex-wrap items-center gap-3 md:flex-nowrap">
          <FormTextInput
            title={{ tx: "notifications.titleInput" }}
            {...register("title", {
              validate: (value) => {
                if (value?.trim() === "") {
                  return tx("notifications.errors.titleInput");
                }
              },
            })}
            error={{ txUnchecked: errors.title?.message }}
          />
          <FormTelephoneInput
            disabled
            control={control}
            name="telephone"
            error={{ txUnchecked: errors.telephone?.message }}
            title={{ tx: "profile.telephone" }}
          />
          <GhostButton
            onClick={() => {
              showProfile();
            }}
            className="mt-7"
          >
            <SvgIcon icon="edit" width={40} height={40} />
          </GhostButton>
        </div>

        <div className="ml-3 flex w-full flex-wrap gap-5">
          <div className="flex gap-4">
            <div className="min-w-lg -mt-2 flex flex-col">
              <label className="label">
                <Text className="label-text" tx="notifications.startOn" />
              </label>
              <input
                className="input-bordered input w-full p-2"
                type="date"
                value={formatDate(date) ?? ""}
                onChange={(newDate) => setDate(new Date(newDate.target.value))}
                onClick={(e) => e.currentTarget.showPicker()}
              />
            </div>
            <TimePicker
              className="mt-7"
              time={sendFromTime}
              onChangeTime={setSendFromTime}
            />
          </div>

          <div className="-mt-2">
            <Tooltip text={{ tx: "notifications.notificationTypeExplanation" }}>
              <label className="label">
                <Text
                  className="label-text"
                  tx="notifications.notificationType"
                />
              </label>
              <Dropdown
                value={notificationType}
                onChange={setNotificaitonType}
                options={notificationTypeOtions}
                noValueSelected={notificationTypeOtions[0].label}
              />
            </Tooltip>
          </div>

          {notificationType === NotificationTypeOptions.RECURRING && (
            <div className="-mt-2">
              <label className="label">
                <Text
                  className="label-text"
                  tx="notifications.frequency.frequency"
                />
              </label>
              <Dropdown
                value={frequency}
                options={notificationFrequencyOptions}
                onChange={setFrequency}
                noValueSelected={notificationFrequencyOptions[0].label}
              />
            </div>
          )}
        </div>

        <FormTextInput
          title={{
            tx:
              notificationType === NotificationTypeOptions.RECURRING
                ? "notifications.messageTypeMedication"
                : "notifications.messageTypeDoctor",
          }}
          {...register("messageTitle", {
            validate: (value) => {
              if (value?.trim() === "") {
                return tx("notifications.errors.messageInputEmpty");
              }
            },
          })}
          placeholder={{
            tx:
              notificationType === NotificationTypeOptions.RECURRING
                ? "notifications.placeholder.medication"
                : "notifications.placeholder.doctorSpecialty",
          }}
          error={{ txUnchecked: errors.messageTitle?.message }}
        />

        <FormTextInput
          title={{ tx: "notifications.messageInput" }}
          {...register("message", {
            validate: (value) => {
              if (value?.trim() === "") {
                return tx("notifications.errors.messageInputEmpty");
              }
            },
          })}
          error={{ txUnchecked: errors.message?.message }}
          placeholder={{
            tx:
              notificationType === NotificationTypeOptions.RECURRING
                ? "notifications.placeholder.dosage"
                : "notifications.placeholder.address",
          }}
        />
      </div>

      <LoadingButton
        tx="notifications.createNotification"
        loading={isSubmitting}
      />
    </Form>
  );
};

const copyDateWithNewTime = (
  currentDate: Date,
  time: TimePickerProps["time"],
) => {
  const currentDateTime = new Date(currentDate || new Date());
  currentDateTime.setHours(time.hours);
  currentDateTime.setMinutes(time.minutes);
  return currentDateTime;
};
