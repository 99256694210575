import { useCallback, useState } from "react";
import { EventTime } from "shared/utils/timeline";
import { twMerge } from "tailwind-merge";

import { EventDetailRecord } from "../../api/generated/multiagent";
import { EnumType, useEnumTitle } from "../../i18n/enum-translation";
import { formatDate } from "../../util/date";
import { useTenantId } from "../../util/use-active-tenant-id";
import { OutlineButton } from "../form/button";
import { Dropdown, DropdownProps } from "../form/dropdown";
import { LoadingButton } from "../form/loading-button";
import { I18nProps, Text } from "../text";

export const EventActionRow: React.FC<{
  dropDownConfig: {
    enumType: EnumType;
    isHidden?: boolean;
    dropDownValue: string;
    noValueSelected: string;
    dropDownStyles?: string;
    dropDownOptions: DropdownProps<string>["options"];
    onChangeDropDownValue: (type: string, event?: EventDetailRecord) => void;
  };
  deleteButtonConfig: {
    isHidden?: boolean;
    deleteButtonLoading?: boolean;
    deleteButtonStyles?: string;
    deleteButtonText: I18nProps;
    onDeleteButtonPress: () => void;
  };
  datePickerConfig?: {
    defaultDate?: EventTime;
    datePickerStyles?: string;
    hideDatePicker: boolean;
    onChangeDate: (time: EventTime) => void;
  };
}> = ({ dropDownConfig, deleteButtonConfig, datePickerConfig }) => {
  const { disease } = useTenantId();

  const [value, setValue] = useState<Date>(
    convertToDate(datePickerConfig?.defaultDate),
  );

  const handleDateChange = useCallback(
    (newDate: string) => {
      setValue(new Date(newDate));
      if (newDate) {
        const [year, month, day] = newDate.split("-").map(Number);
        datePickerConfig?.onChangeDate({ year, month, day });
      }
    },
    [datePickerConfig],
  );

  return (
    <div className="my-2 flex justify-between">
      <div className="flex flex-col justify-end">
        {datePickerConfig && !datePickerConfig?.hideDatePicker && (
          <input
            className={twMerge(
              "input-bordered input w-full",
              datePickerConfig.datePickerStyles,
            )}
            type="date"
            value={formatDate(value) ?? ""}
            onChange={(date) => handleDateChange(date.target.value)}
            onClick={(e) => e.currentTarget.showPicker()}
          />
        )}
        {!dropDownConfig.isHidden ? (
          <Dropdown
            className={twMerge(dropDownConfig.dropDownStyles)}
            value={dropDownConfig.dropDownValue}
            options={dropDownConfig.dropDownOptions}
            onChange={dropDownConfig.onChangeDropDownValue}
            noValueSelected={{ text: dropDownConfig.noValueSelected }}
          />
        ) : (
          <Text
            className="justify-end p-2 font-semibold"
            txUnchecked={`enums.common.detail.${dropDownConfig.noValueSelected}.title`}
            text={useEnumTitle(
              disease,
              dropDownConfig.enumType,
              dropDownConfig.noValueSelected,
            )}
          />
        )}
      </div>
      {!deleteButtonConfig.isHidden && (
        <LoadingButton
          Button={OutlineButton}
          loading={deleteButtonConfig.deleteButtonLoading ?? false}
          className={twMerge(deleteButtonConfig.deleteButtonStyles)}
          onClick={deleteButtonConfig.onDeleteButtonPress}
          {...deleteButtonConfig.deleteButtonText}
        />
      )}
    </div>
  );
};

const convertToDate = (eventTime?: EventTime): Date => {
  return eventTime && eventTime.year && eventTime.month && eventTime.day
    ? new Date(eventTime.year, eventTime.month - 1, eventTime.day)
    : new Date();
};
