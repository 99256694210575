"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.opadeFormDataInputs = exports.defaultFormDataInputs = exports.baseFormDataInputs = void 0;
const form_data_guard_1 = require("../guard/form-data-guard/form-data-guard");
exports.baseFormDataInputs = [
    form_data_guard_1.FormDataCollectionInputType.PROFILE_IMAGE,
    form_data_guard_1.FormDataCollectionInputType.NAME,
    form_data_guard_1.FormDataCollectionInputType.EMAIL,
    form_data_guard_1.FormDataCollectionInputType.PHONE_NUMBER,
    form_data_guard_1.FormDataCollectionInputType.COUNTRY,
    form_data_guard_1.FormDataCollectionInputType.LANGUAGE,
    form_data_guard_1.FormDataCollectionInputType.POSTAL_CODE,
    form_data_guard_1.FormDataCollectionInputType.IS_MEMBER_OF_PAG,
];
exports.defaultFormDataInputs = [
    ...exports.baseFormDataInputs,
    form_data_guard_1.FormDataCollectionInputType.AGREED_TO_BEING_LISTED,
];
exports.opadeFormDataInputs = [
    ...exports.baseFormDataInputs,
    form_data_guard_1.FormDataCollectionInputType.OPADE_PATIENT_ID,
];
