import {
  QuestionNodeSubtype,
  QuestionNodeType,
} from "shared/model/websocket/schema";

import { CountryDropdown } from "./components/country-dropdown";
import { EmailInput } from "./components/email-input";
import { EndoStudyAgreement } from "./components/endo-study-agreement";
import { IntroductionModal } from "./components/introduction-modal";
import { OpadeStudyIdInput } from "./components/opade-study-id-input";
import { OrganisationDropdown } from "./components/organisation-dropdown";
import { PhoneTextInput } from "./components/phone-input";
import { SingleChoice } from "./components/single-choice";
import { Terms } from "./components/terms";
import { TextDrawer } from "./components/text-drawer";
import { TextInput } from "./components/text-input";
import { YearOfBirth } from "./components/year-of-birth";
import { emailValidation } from "./validation/email";
import { createValidationFunctionForMinLength } from "./validation/min-length";
import { opadeIdValidation } from "./validation/opade-study-id";
import { phoneValidation } from "./validation/phone";
import { yearOfBirthValidation } from "./validation/year-of-birth";
import { I18nProps, TranslationFeKey } from "../../../text";
import { OnMessageSendFunction } from "../conversation";

class FallbackLookup {
  private defaultValue: ComponentTuple;
  private map: Map<string, ComponentTuple>;

  public constructor(
    defaultValue: ComponentTuple,
    entries?: readonly (readonly [TypeTuple, ComponentTuple])[],
  ) {
    this.map = new Map(entries?.map(([key, value]) => [key.toString(), value]));
    this.defaultValue = defaultValue;
  }

  public get(key: TypeTuple): ComponentTuple {
    const string_key = key.toString();
    const fallback_key = [key[0], undefined].toString();

    return (
      this.map.get(string_key) ??
      this.map.get(fallback_key) ??
      this.defaultValue
    );
  }
}

export type ValidationFunction = (value: string | number) => {
  ok: boolean;
  message?: I18nProps;
};

export type TypeTuple = [QuestionNodeType, QuestionNodeSubtype?];
export type ComponentTuple = [
  React.FC<ChatOptionComponentProps>,
  ValidationFunction?,
];

export const chatDrawerComponents: FallbackLookup = new FallbackLookup(
  [TextDrawer, undefined],
  // prettier-ignore
  [ 
      [["TEXT", "MARKETING_CHANNEL"], [TextInput, createValidationFunctionForMinLength(5)]],
      [["TEXT", "MIGRATION_BACKGROUND"], [TextInput, createValidationFunctionForMinLength(4)]],
      [["TEXT", undefined], [TextInput, undefined]],

      [["QUESTION", undefined], [TextDrawer, undefined]],
      
      [["APPROVAL", "ACKNOWLEDGE"], [SingleChoice, undefined]],
      [["APPROVAL", "TERMS"], [Terms, undefined]],
      [["APPROVAL", "ENDO_STUDY_AGREEMENT"], [EndoStudyAgreement, undefined]],

      [["DYNAMIC", "PAG"], [OrganisationDropdown, undefined]],
      [["DYNAMIC", "COUNTRY"], [CountryDropdown, undefined]],

      [["MODAL", "INTRODUCTION"], [IntroductionModal, undefined]],

      // [["MULTIPLE_CHOICE", undefined], [MultipleChoice, undefined]],
      // [["MULTIPLE_ENUM", undefined], [MultipleChoice, undefined]],

      [["REGEX", "PHONE"], [PhoneTextInput, phoneValidation]],
      [["REGEX", "YEAR_OF_BIRTH"], [YearOfBirth, yearOfBirthValidation]],
      [["REGEX", "OPADE_STUDY_ID"], [OpadeStudyIdInput, opadeIdValidation]],
      [["REGEX", "POSTAL_CODE"], [TextInput, createValidationFunctionForMinLength(3)]],
      [["REGEX", undefined], [TextInput, undefined]],

      [["SIGNUP", "EMAIL"], [EmailInput, emailValidation]],

      [["SINGLE_CHOICE", undefined], [SingleChoice, undefined]],
      [["SINGLE_ENUM", "GENDER"], [SingleChoice, undefined]],
    ],
);
export interface ChatOptionComponentProps {
  choices: TranslationFeKey[];
  // questionNodeInfo: FrontendQuestionNodeInfo;
  sendResponse: OnMessageSendFunction;
}
