/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Embedding } from './Embedding';
import {
    EmbeddingFromJSON,
    EmbeddingFromJSONTyped,
    EmbeddingToJSON,
} from './Embedding';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';
import type { QuestionVisit } from './QuestionVisit';
import {
    QuestionVisitFromJSON,
    QuestionVisitFromJSONTyped,
    QuestionVisitToJSON,
} from './QuestionVisit';
import type { QuestionnaireGraph } from './QuestionnaireGraph';
import {
    QuestionnaireGraphFromJSON,
    QuestionnaireGraphFromJSONTyped,
    QuestionnaireGraphToJSON,
} from './QuestionnaireGraph';
import type { Subtype } from './Subtype';
import {
    SubtypeFromJSON,
    SubtypeFromJSONTyped,
    SubtypeToJSON,
} from './Subtype';

/**
 * Represents a QuestionNode record
 * @export
 * @interface QuestionNode
 */
export interface QuestionNode {
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    nodeId: string;
    /**
     * 
     * @type {NodeType}
     * @memberof QuestionNode
     */
    type: NodeType;
    /**
     * 
     * @type {Subtype}
     * @memberof QuestionNode
     */
    subtype?: Subtype;
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    informationNeeded?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionNode
     */
    choices: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionNode
     */
    isMedical: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionNode
     */
    loopThreshold?: number;
    /**
     * 
     * @type {QuestionnaireGraph}
     * @memberof QuestionNode
     */
    questionnaireGraph?: QuestionnaireGraph;
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    questionnaireGraphId: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof QuestionNode
     */
    message?: Array<Message>;
    /**
     * 
     * @type {Array<QuestionVisit>}
     * @memberof QuestionNode
     */
    questionVisit?: Array<QuestionVisit>;
    /**
     * 
     * @type {Embedding}
     * @memberof QuestionNode
     */
    embedding?: Embedding;
    /**
     * 
     * @type {string}
     * @memberof QuestionNode
     */
    embeddingId: string;
}

/**
 * Check if a given object implements the QuestionNode interface.
 */
export function instanceOfQuestionNode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "nodeId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "choices" in value;
    isInstance = isInstance && "isMedical" in value;
    isInstance = isInstance && "questionnaireGraphId" in value;
    isInstance = isInstance && "embeddingId" in value;

    return isInstance;
}

export function QuestionNodeFromJSON(json: any): QuestionNode {
    return QuestionNodeFromJSONTyped(json, false);
}

export function QuestionNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nodeId': json['nodeId'],
        'type': NodeTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : SubtypeFromJSON(json['subtype']),
        'question': json['question'],
        'informationNeeded': !exists(json, 'informationNeeded') ? undefined : json['informationNeeded'],
        'choices': json['choices'],
        'isMedical': json['isMedical'],
        'loopThreshold': !exists(json, 'loopThreshold') ? undefined : json['loopThreshold'],
        'questionnaireGraph': !exists(json, 'questionnaireGraph') ? undefined : QuestionnaireGraphFromJSON(json['questionnaireGraph']),
        'questionnaireGraphId': json['questionnaireGraphId'],
        'message': !exists(json, 'message') ? undefined : ((json['message'] as Array<any>).map(MessageFromJSON)),
        'questionVisit': !exists(json, 'questionVisit') ? undefined : ((json['questionVisit'] as Array<any>).map(QuestionVisitFromJSON)),
        'embedding': !exists(json, 'embedding') ? undefined : EmbeddingFromJSON(json['embedding']),
        'embeddingId': json['embeddingId'],
    };
}

export function QuestionNodeToJSON(value?: QuestionNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nodeId': value.nodeId,
        'type': NodeTypeToJSON(value.type),
        'subtype': SubtypeToJSON(value.subtype),
        'question': value.question,
        'informationNeeded': value.informationNeeded,
        'choices': value.choices,
        'isMedical': value.isMedical,
        'loopThreshold': value.loopThreshold,
        'questionnaireGraph': QuestionnaireGraphToJSON(value.questionnaireGraph),
        'questionnaireGraphId': value.questionnaireGraphId,
        'message': value.message === undefined ? undefined : ((value.message as Array<any>).map(MessageToJSON)),
        'questionVisit': value.questionVisit === undefined ? undefined : ((value.questionVisit as Array<any>).map(QuestionVisitToJSON)),
        'embedding': EmbeddingToJSON(value.embedding),
        'embeddingId': value.embeddingId,
    };
}

