import { useEffect } from "react";
import { FrontendPageEnum } from "shared/model/pages";
import { TranslationFeKey } from "shared/types/translation-key";

import { toTenantIdHeader, useApiMutation } from "../../../../api/use-api";
import { LoadingScreen } from "../../../../components/loading-screen";
import { userHasFinishedSignup } from "../../../../util/data-layer-actions";
import { useNavigateInsideTenant } from "../../../../util/navigation-hooks";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { DataGuardImplementation } from "../types";

export const blankGuardImplementation: DataGuardImplementation = {
  useWasCompleted: () => {
    const user = useUser();
    return user?.userAttributes?.finishedSignUp;
  },
  Component: () => <MarkSignUpAsDone />,
};

const MarkSignUpAsDone: React.FC = () => {
  const user = useUser();
  const navigate = useNavigateInsideTenant();
  const tenantId = useTenantId();

  const { mutate: markSignUpAsDone } = useApiMutation(
    "backend",
    (api) => () => api.markSignUpAsDone(toTenantIdHeader(tenantId)),
    undefined,
    undefined,
    {
      onSuccess: () => {
        userHasFinishedSignup();
        navigate(FrontendPageEnum.CHAT, {
          state: {
            tooltipMessage: !user?.userAttributes?.finishedSignUpViaCall && {
              tx: "menuTooltip" as TranslationFeKey,
            },
            isNavOpen: !!user?.userAttributes?.finishedSignUpViaCall,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (!user?.userAttributes?.finishedSignUp) {
      markSignUpAsDone();
    }
  }, [markSignUpAsDone, user?.userAttributes?.finishedSignUp]);

  return <LoadingScreen />;
};
