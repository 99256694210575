import React from "react";

import { BasicWithButton } from "./fragments/basic-with-button";
import { TextInput } from "../../../../form/text-input";
import { ChatOptionComponentProps } from "../lookup";

export const OpadeStudyIdInput: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [studyId, setStudyId] = React.useState("");

  return (
    <BasicWithButton sendResponse={sendResponse} message={studyId}>
      <TextInput
        type="text"
        onChange={(e) => setStudyId(e.target.value)}
        placeholder={{ tx: "signUp.customFields.opadePatientId.placeholder" }}
      />
    </BasicWithButton>
  );
};
