import { FC, useCallback, useMemo } from "react";

import { CommunityUser } from "../../api/generated/backend";
import { i18nPropsHaveContent } from "../../i18n/i18n-props-have-content";
import { useT } from "../../i18n/use-t";
import { openEmailProgram } from "../../util/open-email-program";
import { textOnWhatsapp } from "../../util/text-on-whatsapp";
import { Avatar } from "../avatar";
import { Button } from "../form/button";
import { DropdownProps } from "../form/dropdown";
import { PlainI18nProps } from "../text";
import { Text } from "../text";

const ProfileItem: FC<{
  label: PlainI18nProps;
  value: string | undefined;
  className?: string;
}> = ({ label, value, className }) => {
  const t = useT();
  return value ? (
    <div className={className}>
      <div className="text-gray-700">
        {i18nPropsHaveContent(label) && t(label)}
      </div>
      <div className="text-lg font-bold">{value}</div>
    </div>
  ) : null;
};

export const MemberProfile: FC<{ communityUser: CommunityUser }> = ({
  communityUser: user,
}) => {
  const t = useT();

  const onSendEmail = useCallback(() => {
    openEmailProgram({
      cc: "support@mamahealth.com",
      to: user.email ?? "",
      subject: t({ tx: "community.message.subject" }),
      body: t({ tx: "community.message.body" }),
    });
  }, [user, t]);

  const onSendWhatsAppMessage = useCallback(() => {
    if (user.telephone) {
      textOnWhatsapp({
        phoneNumber: user.telephone,
        message: t({ tx: "community.message.body" }),
      });
    }
  }, [user, t]);

  const communityMemberProfileItems: DropdownProps<
    string | number | undefined
  >["options"] = useMemo(
    () => [
      { label: { tx: "memberProfile.name" }, value: user.name },
      { label: { tx: "memberProfile.email" }, value: user.email },
      { label: { tx: "memberProfile.age" }, value: user.age },
      { label: { tx: "memberProfile.hometown" }, value: user.hometown },
      { label: { tx: "memberProfile.currentTown" }, value: user.currentTown },
      { label: { tx: "memberProfile.telephone" }, value: user.telephone },
    ],
    [user],
  );

  return (
    <div className="flex w-full shrink-0 flex-col justify-center gap-4 p-[20px] xl:max-w-[600px] 2xl:max-w-[700px]">
      <div className="flex justify-center">
        <Avatar
          alt={user.name ?? "community user"}
          src={user.avatarUrl ?? user.avatarUrl}
        />
      </div>
      {communityMemberProfileItems.map((profileItem) => (
        <ProfileItem
          key={profileItem.label.tx}
          label={profileItem.label}
          value={profileItem.value?.toString()}
        />
      ))}
      <div className="card-actions justify-center pt-2">
        {user.telephone && (
          <Button className="btn-secondary" onClick={onSendWhatsAppMessage}>
            <Text as="p" tx="community.textOnWhatsapp" />
          </Button>
        )}
        {user.email && (
          <Button className="btn-primary" onClick={onSendEmail}>
            <Text as="p" tx="community.sendEmail" />
          </Button>
        )}
      </div>
    </div>
  );
};
