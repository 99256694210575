/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganisationGroups
 */
export interface OrganisationGroups {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisationGroups
     */
    groups: Array<OrganisationGroupsGroupsEnum>;
    /**
     * 
     * @type {string}
     * @memberof OrganisationGroups
     */
    organisation: OrganisationGroupsOrganisationEnum;
}


/**
 * @export
 */
export const OrganisationGroupsGroupsEnum = {
    User: 'USER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Pag: 'PAG',
    Pharma: 'PHARMA'
} as const;
export type OrganisationGroupsGroupsEnum = typeof OrganisationGroupsGroupsEnum[keyof typeof OrganisationGroupsGroupsEnum];

/**
 * @export
 */
export const OrganisationGroupsOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type OrganisationGroupsOrganisationEnum = typeof OrganisationGroupsOrganisationEnum[keyof typeof OrganisationGroupsOrganisationEnum];


/**
 * Check if a given object implements the OrganisationGroups interface.
 */
export function instanceOfOrganisationGroups(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "organisation" in value;

    return isInstance;
}

export function OrganisationGroupsFromJSON(json: any): OrganisationGroups {
    return OrganisationGroupsFromJSONTyped(json, false);
}

export function OrganisationGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': json['groups'],
        'organisation': json['organisation'],
    };
}

export function OrganisationGroupsToJSON(value?: OrganisationGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': value.groups,
        'organisation': value.organisation,
    };
}

