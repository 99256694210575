/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface GroupsAndSubscriptions
 */
export interface GroupsAndSubscriptions {
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof GroupsAndSubscriptions
     */
    activeSubscriptions: Array<Subscription>;
    /**
     * 
     * @type {string}
     * @memberof GroupsAndSubscriptions
     */
    organisation: GroupsAndSubscriptionsOrganisationEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupsAndSubscriptions
     */
    groups: Array<GroupsAndSubscriptionsGroupsEnum>;
}


/**
 * @export
 */
export const GroupsAndSubscriptionsOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GroupsAndSubscriptionsOrganisationEnum = typeof GroupsAndSubscriptionsOrganisationEnum[keyof typeof GroupsAndSubscriptionsOrganisationEnum];

/**
 * @export
 */
export const GroupsAndSubscriptionsGroupsEnum = {
    User: 'USER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Pag: 'PAG',
    Pharma: 'PHARMA'
} as const;
export type GroupsAndSubscriptionsGroupsEnum = typeof GroupsAndSubscriptionsGroupsEnum[keyof typeof GroupsAndSubscriptionsGroupsEnum];


/**
 * Check if a given object implements the GroupsAndSubscriptions interface.
 */
export function instanceOfGroupsAndSubscriptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activeSubscriptions" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "groups" in value;

    return isInstance;
}

export function GroupsAndSubscriptionsFromJSON(json: any): GroupsAndSubscriptions {
    return GroupsAndSubscriptionsFromJSONTyped(json, false);
}

export function GroupsAndSubscriptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupsAndSubscriptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeSubscriptions': ((json['activeSubscriptions'] as Array<any>).map(SubscriptionFromJSON)),
        'organisation': json['organisation'],
        'groups': json['groups'],
    };
}

export function GroupsAndSubscriptionsToJSON(value?: GroupsAndSubscriptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeSubscriptions': ((value.activeSubscriptions as Array<any>).map(SubscriptionToJSON)),
        'organisation': value.organisation,
        'groups': value.groups,
    };
}

