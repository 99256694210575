import { OrganisationsPerDiseaseMultiSelect } from "../../../../form/organisations-per-disease-multiselect";
import { ChatOptionComponentProps } from "../lookup";

export const OrganisationDropdown: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  return (
    <OrganisationsPerDiseaseMultiSelect
      searchResultsOnTop
      className="max-w-md"
      onChange={(value) =>
        sendResponse({ message: value.join(", "), selection: value })
      }
    />
  );
};
