import React from "react";

import { AiChatNewConversation } from "./ai-chat-new-conversation";
import { AiPrivateChat } from "./ai-private-chat";
import { useApiQuery } from "../../../api/use-api";
import { CONVERSATIONS_KEY } from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { LoadingScreen } from "../../loading-screen";

export const AiChat: React.FC = () => {
  const { disease } = useTenantId();

  const { data: conversations } = useApiQuery(
    "multiagent",
    (api) => api.getConversations({ mamaDisease: disease }),
    CONVERSATIONS_KEY(disease),
    undefined,
    { refetchOnWindowFocus: false },
  );

  return conversations && conversations.length === 0 ? (
    <AiChatNewConversation />
  ) : conversations ? (
    <AiPrivateChat conversationId={conversations?.[0]?.id} />
  ) : (
    <LoadingScreen />
  );
};
