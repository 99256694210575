import { CommunityUser } from "../../api/generated/backend";
import { Avatar } from "../avatar";
import { Text } from "../text";

type MemberCardProps = {
  data: CommunityUser;
  onClick: (communityUser: CommunityUser) => void;
};

export const MemberCard: React.FC<MemberCardProps> = ({
  data: user,
  onClick,
}) => {
  return (
    <div
      className="card card-side cursor-pointer flex-col items-center bg-base-100 p-5 shadow-xl sm:flex-row"
      onClick={() => onClick(user)}
    >
      <div className="flex h-40 w-40 items-center">
        <Avatar src={user.avatarUrl} alt="User avatar" />
      </div>
      <div className="card-body p-0 px-5">
        <h2 className="card-title block text-center sm:text-left">
          {user.name}
        </h2>
        {user.email && (
          <Text
            as="p"
            tx="community.email"
            txData={{ email: user.email }}
            txComponents={{
              strong: <strong />,
            }}
          />
        )}
        {user.age !== undefined && (
          <Text
            as="p"
            tx="community.age"
            txData={{ age: user.age }}
            txComponents={{
              strong: <strong />,
            }}
          />
        )}
        {user.hometown && (
          <Text
            as="p"
            tx="community.hometown"
            txData={{ hometown: user.hometown }}
            txComponents={{
              strong: <strong />,
            }}
          />
        )}
        {user.hometown && (
          <Text
            as="p"
            tx="community.currentTown"
            txData={{ currentTown: user.currentTown }}
            txComponents={{
              strong: <strong />,
            }}
          />
        )}
      </div>
    </div>
  );
};
