/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const UserGroup = {
    User: 'USER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Pag: 'PAG',
    Pharma: 'PHARMA'
} as const;
export type UserGroup = typeof UserGroup[keyof typeof UserGroup];


export function UserGroupFromJSON(json: any): UserGroup {
    return UserGroupFromJSONTyped(json, false);
}

export function UserGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroup {
    return json as UserGroup;
}

export function UserGroupToJSON(value?: UserGroup | null): any {
    return value as any;
}

