import { useState } from "react";

import { BasicWithButton } from "./fragments/basic-with-button";
import { TextInput } from "../../../../form/text-input";
import { ChatOptionComponentProps } from "../lookup";

export const YearOfBirth: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [text, setText] = useState("");

  return (
    <BasicWithButton sendResponse={sendResponse} message={text}>
      <TextInput
        type="number"
        placeholder={{ tx: "userData.dateOfBirth" }}
        onChange={(e) => setText(e.target.value)}
      />
    </BasicWithButton>
  );
};
