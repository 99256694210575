/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';
import type { QuestionnaireGraph } from './QuestionnaireGraph';
import {
    QuestionnaireGraphFromJSON,
    QuestionnaireGraphFromJSONTyped,
    QuestionnaireGraphToJSON,
} from './QuestionnaireGraph';
import type { QuestionnaireState } from './QuestionnaireState';
import {
    QuestionnaireStateFromJSON,
    QuestionnaireStateFromJSONTyped,
    QuestionnaireStateToJSON,
} from './QuestionnaireState';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Conversation record
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    id: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Conversation
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    inputTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    type?: string;
    /**
     * 
     * @type {User}
     * @memberof Conversation
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof Conversation
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    diseaseId: string;
    /**
     * 
     * @type {QuestionnaireGraph}
     * @memberof Conversation
     */
    questionnaireGraph?: QuestionnaireGraph;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    questionnaireGraphId?: string;
    /**
     * 
     * @type {QuestionnaireState}
     * @memberof Conversation
     */
    questionnaireState?: QuestionnaireState;
}

/**
 * Check if a given object implements the Conversation interface.
 */
export function instanceOfConversation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function ConversationFromJSON(json: any): Conversation {
    return ConversationFromJSONTyped(json, false);
}

export function ConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Conversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'language': json['language'],
        'inputTime': (new Date(json['inputTime'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'questionnaireGraph': !exists(json, 'questionnaireGraph') ? undefined : QuestionnaireGraphFromJSON(json['questionnaireGraph']),
        'questionnaireGraphId': !exists(json, 'questionnaireGraphId') ? undefined : json['questionnaireGraphId'],
        'questionnaireState': !exists(json, 'questionnaireState') ? undefined : QuestionnaireStateFromJSON(json['questionnaireState']),
    };
}

export function ConversationToJSON(value?: Conversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageToJSON)),
        'language': value.language,
        'inputTime': (value.inputTime.toISOString()),
        'type': value.type,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'questionnaireGraph': QuestionnaireGraphToJSON(value.questionnaireGraph),
        'questionnaireGraphId': value.questionnaireGraphId,
        'questionnaireState': QuestionnaireStateToJSON(value.questionnaireState),
    };
}

