import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";

import { PlainI18nProps } from "../../../../components/text";

export interface CognitoError {
  code: string;
  message: string;
}

export function isErrorWithCode(error: unknown): error is CognitoError {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    "message" in error
  );
}

export const returnLocalizedCognitoError = (
  errorCode: string,
  errorMessage: string,
): PlainI18nProps => {
  if (errorCode === "UserLambdaValidationException") {
    const split = errorMessage.split(" failed with error ");

    return split.length === 2
      ? {
          txUnchecked: `auth.signIn.errors.${split[2]}`,
          text: errorMessage,
        }
      : { text: errorMessage };
  }

  return {
    txUnchecked: `auth.signIn.errors.${errorCode}`,
    txData: { min_password_length: MIN_PASSWORD_LENGTH },
    text: errorMessage,
  };
};
