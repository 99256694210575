import { ChoicesWithText } from "./fragments/choices-with-text";
import { Link } from "../../../../link";
import { Text } from "../../../../text";
import { ChatOptionComponentProps } from "../lookup";

export const EndoStudyAgreement: React.FC<ChatOptionComponentProps> = (
  props,
) => {
  return (
    <ChoicesWithText {...props}>
      <Text
        className="label-text py-2 font-bold"
        tx="signUp.customFields.endoChariteStudy.studyInformation"
        txComponents={{
          href: <Link className="underline" target="_blank" />,
        }}
      />
    </ChoicesWithText>
  );
};
