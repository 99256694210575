import { PropsWithChildren, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import {
  PUBLIC_CHAT_LOCAL_STORAGE_KEY,
  PublicChatCredentials,
} from "./chat/ai/ai-public-chat";
import { LoadingScreen } from "./loading-screen";
import {
  CreateUserRequest,
  RegisterUserRequest,
  UpdateUserAttributesDto,
} from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { queryClient } from "../query-client";
import { ME_KEY, USER_EXISTS_KEY } from "../types/query-keys";
import {
  userHasConfirmedAccount,
  userHasFinishedSignup,
} from "../util/data-layer-actions";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

export const CreateUserGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const tenantId = useTenantId();
  const creds = localStorage.getItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);

  const user = useUser();

  const {
    i18n: { language: selectedLanguage },
  } = useTranslation();

  const { data: userExistsResponse } = useApiQuery(
    "backend",
    (client) => client.userExists(),
    USER_EXISTS_KEY,
  );

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
      api.updateUserAttributes({ updateUserAttributesDto }),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  const { mutate: createUser } = useApiMutation(
    "backend",
    (client) => (params: CreateUserRequest) => client.createUser(params),
    undefined,
    undefined,
    {
      onSuccess: () => {
        userHasConfirmedAccount();
        queryClient.invalidateQueries(USER_EXISTS_KEY);
        updateUserAttributes({
          language: (selectedLanguage ??
            localStorage.getItem("language") ??
            undefined) as Language | undefined,
        });
      },
    },
  );

  const { mutate: registerUser } = useApiMutation(
    "backend",
    (client) => (params: RegisterUserRequest) => client.registerUser(params),
    undefined,
    undefined,
    {
      onSuccess() {
        localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
      },
    },
  );

  const { mutate: markSignUpAsDone } = useApiMutation(
    "backend",
    (api) => () => api.markSignUpAsDone(toTenantIdHeader(tenantId)),
    undefined,
    undefined,
    {
      onSuccess: userHasFinishedSignup,
    },
  );

  useEffect(() => {
    if (user && !user?.userAttributes?.finishedSignUp) {
      markSignUpAsDone();
    }
  }, [markSignUpAsDone, user]);

  useEffect(() => {
    if (
      creds &&
      userExistsResponse &&
      !userExistsResponse.userExists &&
      process.env.REACT_APP_IS_LOCAL === "1"
    ) {
      // This is only for local development, also the endpoint is only available in local development
      const { userId, token }: PublicChatCredentials = JSON.parse(creds);
      registerUser({ registerUserDto: { userId, token } });
    }

    if (userExistsResponse && !userExistsResponse.userExists) {
      createUser(toTenantIdHeader(tenantId));
    }
  }, [createUser, creds, registerUser, tenantId, userExistsResponse]);

  return userExistsResponse?.userExists ? (
    (children as ReactElement)
  ) : (
    <LoadingScreen />
  );
};
