"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cognitoInfo = exports.MIN_PASSWORD_LENGTH = void 0;
const base_url_of_tenant_1 = require("./base-url-of-tenant");
const deployment_environments_1 = require("./deployment-environments");
const deployment_environment_1 = require("../model/deployment-environment");
exports.MIN_PASSWORD_LENGTH = 8;
exports.cognitoInfo = (0, deployment_environment_1.perDeploymentEnv)({
    [deployment_environments_1.DeploymentEnvironment.DEV]: (tenantId, isLocalhost) => ({
        userPoolId: "eu-central-1_pQnbyg9RA",
        identityPoolId: "eu-central-1:098c6374-79f8-4d03-b7eb-1be1b98d4702",
        userPoolClientId: "10svjgt32g1je9o4drb01stst5",
        loginWith: {
            oauth: {
                scopes: [],
                domain: "ailc-dev-mama-login.auth.eu-central-1.amazoncognito.com",
                redirectSignIn: [
                    `${(0, base_url_of_tenant_1.baseUrlOfTenant)({
                        tenantId,
                        subdomainElement: deployment_environments_1.subdomainOfDeploymentEnv[deployment_environments_1.DeploymentEnvironment.DEV],
                        isLocalhost,
                    })}/auth/social-redirect-handler`,
                ],
                redirectSignOut: [
                    `${(0, base_url_of_tenant_1.baseUrlOfTenant)({
                        tenantId,
                        subdomainElement: deployment_environments_1.subdomainOfDeploymentEnv[deployment_environments_1.DeploymentEnvironment.DEV],
                        isLocalhost,
                    })}/auth/social-logout-handler`,
                ],
                responseType: "code",
            },
        },
    }),
    [deployment_environments_1.DeploymentEnvironment.PROD]: (tenantId, isLocalhost) => ({
        userPoolId: "eu-central-1_CTvRPRFEp",
        identityPoolId: "eu-central-1:c3e562a0-f506-4bef-9144-5cf00b784f29",
        userPoolClientId: "4bae4e8aqnjcjjc4hfgj8unp08",
        loginWith: {
            oauth: {
                scopes: [],
                domain: "ailc-prod-mama-login.auth.eu-central-1.amazoncognito.com",
                redirectSignIn: [
                    `${(0, base_url_of_tenant_1.baseUrlOfTenant)({
                        tenantId,
                        subdomainElement: deployment_environments_1.subdomainOfDeploymentEnv[deployment_environments_1.DeploymentEnvironment.PROD],
                        isLocalhost,
                    })}/auth/social-redirect-handler`,
                ],
                redirectSignOut: [
                    `${(0, base_url_of_tenant_1.baseUrlOfTenant)({
                        tenantId,
                        subdomainElement: deployment_environments_1.subdomainOfDeploymentEnv[deployment_environments_1.DeploymentEnvironment.PROD],
                        isLocalhost,
                    })}/auth/social-logout-handler`,
                ],
                responseType: "code",
            },
        },
    }),
});
