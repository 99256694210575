import { EnabledPage, FrontendPageEnum, IFramePage } from "shared/model/pages";

import { frontendPages } from "./frontend-pages";
import { BareTenantPage } from "./use-tenant-pages";

export const isIFramePage = (
  maybeIFramePage: BareTenantPage["page"],
): maybeIFramePage is IFramePage => !(maybeIFramePage.route in frontendPages);

export const isForumPage = (
  maybeForumPage: BareTenantPage["page"],
): maybeForumPage is EnabledPage<FrontendPageEnum.FORUM> =>
  maybeForumPage.route === FrontendPageEnum.FORUM;
