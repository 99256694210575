import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { SignInResponse, SignInStatus } from "./cognito-magic-link-sign-up";
import { cognitoPaths } from "./paths";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { Link } from "../../../../components/link";
import { Text, tx } from "../../../../components/text";
import { userHasInteractedWithSignupForm } from "../../../../util/data-layer-actions";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";

export const CognitoMagicLinkSignIn: React.FC = () => {
  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
  }>({ defaultValues: prefillValues });

  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  useEffect(() => {
    if (isDirty) {
      userHasInteractedWithSignupForm();
    }
  }, [isDirty]);

  const {
    i18n: { language },
  } = useTranslation();
  const { disease, organisation } = useTenantId();

  const signUserIn = useCallback(
    async ({
      username,
      email,
      name,
    }: {
      username: string;
      email: string;
      name: string;
    }): Promise<SignInResponse> => {
      const clientMetadata: CognitoClientMetadata & { [key: string]: string } =
        {
          disease,
          ...(organisation ? { organisation } : {}),
          isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
          authFlowType: "CUSTOM_WITHOUT_SRP",
          signInMethod: "MAGIC_LINK",
          alreadyHaveMagicLink: "no",
          language,
        };

      try {
        const { isSignedIn, nextStep } = await signIn({
          username,
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
            userAttributes: {
              name,
              email,
            },
            autoSignIn: false,
            clientMetadata,
          },
        });

        if (
          !isSignedIn &&
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          const { isSignedIn: configmSignedIn, nextStep: confirmNextStep } =
            await confirmSignIn({
              challengeResponse: "__dummy__",
              options: { clientMetadata },
            });

          if (
            !configmSignedIn &&
            confirmNextStep.signInStep ===
              "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
          ) {
            return { status: SignInStatus.MagicLinkSent };
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.name === "UserNotFoundException") {
            return { status: SignInStatus.UserNotFound, error: err };
          }

          return { status: SignInStatus.UnknownError, error: err };
        }

        return { status: SignInStatus.UnknownError };
      }

      return { status: SignInStatus.UnknownError };
    },
    [disease, organisation, language],
  );

  const onSubmitTrySignUp = handleSubmit(({ email, name }) => {
    return signUserIn({ username: email, email, name }).then((result) => {
      switch (result.status) {
        case SignInStatus.MagicLinkSent:
          navigate({
            to: { type: "uri", uri: cognitoPaths.confirmSignInInfo },
            replace: true,
          });
          break;
        case SignInStatus.UserNotFound:
          alert("User not found: " + result.error);
          break;
        case SignInStatus.UnknownError:
          alert("Unknown sign in error: " + result.error);
          break;
      }
    });
  });

  return (
    <Form onSubmit={onSubmitTrySignUp}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        tx="auth.signIn.signInButton"
      />
      <Text
        as="p"
        tx="auth.navigation.signInWithPassword"
        txComponents={{
          SignInWithPasswordLink: (
            <Link
              onClick={() =>
                navigate({
                  to: { type: "uri", uri: cognitoPaths.signIn },
                })
              }
            />
          ),
        }}
      />
    </Form>
  );
};
