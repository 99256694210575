/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { StudySignUp } from './StudySignUp';
import {
    StudySignUpFromJSON,
    StudySignUpFromJSONTyped,
    StudySignUpToJSON,
} from './StudySignUp';

/**
 * Represents a Study record
 * @export
 * @interface Study
 */
export interface Study {
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    imageLink: string;
    /**
     * 
     * @type {boolean}
     * @memberof Study
     */
    isPublished: boolean;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    termsLink: string;
    /**
     * 
     * @type {Date}
     * @memberof Study
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Study
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<StudySignUp>}
     * @memberof Study
     */
    studySignUp?: Array<StudySignUp>;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof Study
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    diseaseId: string;
    /**
     * 
     * @type {OrganisationSettings}
     * @memberof Study
     */
    organisation?: OrganisationSettings;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    organisationId?: string;
}

/**
 * Check if a given object implements the Study interface.
 */
export function instanceOfStudy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "headline" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageLink" in value;
    isInstance = isInstance && "isPublished" in value;
    isInstance = isInstance && "termsLink" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function StudyFromJSON(json: any): Study {
    return StudyFromJSONTyped(json, false);
}

export function StudyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Study {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'headline': json['headline'],
        'description': json['description'],
        'imageLink': json['imageLink'],
        'isPublished': json['isPublished'],
        'termsLink': json['termsLink'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'studySignUp': !exists(json, 'studySignUp') ? undefined : ((json['studySignUp'] as Array<any>).map(StudySignUpFromJSON)),
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationSettingsFromJSON(json['organisation']),
        'organisationId': !exists(json, 'organisationId') ? undefined : json['organisationId'],
    };
}

export function StudyToJSON(value?: Study | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'headline': value.headline,
        'description': value.description,
        'imageLink': value.imageLink,
        'isPublished': value.isPublished,
        'termsLink': value.termsLink,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'studySignUp': value.studySignUp === undefined ? undefined : ((value.studySignUp as Array<any>).map(StudySignUpToJSON)),
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'organisation': OrganisationSettingsToJSON(value.organisation),
        'organisationId': value.organisationId,
    };
}

