import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { TenantIdentifier } from "shared/config";
import { FrontendPageEnum } from "shared/model/pages";
import { twMerge } from "tailwind-merge";

import { ActionMenu, ActionMenuAction } from "./form/action-menu";
import { Form } from "./form/form";
import { FormCheckboxInput } from "./form/form-checkbox-input";
import { LoadingButton } from "./form/loading-button";
import { Link } from "./link";
import { Text, tx } from "./text";
import {
  DeleteStudyRequest,
  Study as StudyModel,
} from "../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../api/use-api";
import { useModal } from "../models/modal-provider";
import { queryClient } from "../query-client";
import {
  ME_KEY,
  STUDIES_KEY,
  UNPUBLISHED_STUDIES_KEY,
} from "../types/query-keys";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export type StudyProps = JSX.IntrinsicElements["div"] & {
  study: StudyModel;
  isAdmin?: boolean;
  disabled?: boolean;
};

type StudySignUpForm = {
  acceptTerms: boolean;
  shareStory: boolean;
};

export const Study: React.FC<StudyProps> = ({
  study,
  className,
  isAdmin = false,
  disabled = isAdmin,
  ...rest
}) => {
  const tenantId = useTenantId();
  const navigate = useNavigateInsideTenant();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  const isSignedUp = !!user?.studySignUps.find(
    (studySignUp) => studySignUp.studyId === study.id,
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<StudySignUpForm>();

  const { showModal, hideModal } = useModal();

  const { mutate: signUpToStudy, isLoading: signUpIsLoading } = useApiMutation(
    "backend",
    (api) => (_tenantId: TenantIdentifier) =>
      api.createStudySignUp({
        createStudySignUpDto: {
          studyId: study.id,
        },
      }),
    undefined,
    undefined,
    {
      onSuccess: (_, updatedTenantId) => {
        queryClient.invalidateQueries(ME_KEY(updatedTenantId));
      },
    },
  );

  const { mutate: deleteStudy } = useApiMutation(
    "backend",
    (api) => (request: DeleteStudyRequest) => api.deleteStudy(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(STUDIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          UNPUBLISHED_STUDIES_KEY(toTenantId(header)),
        );
      },
    },
  );

  const { mutate: publishStudy } = useApiMutation(
    "backend",
    (api) => (request: DeleteStudyRequest) => api.publishStudy(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(STUDIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          UNPUBLISHED_STUDIES_KEY(toTenantId(header)),
        );
      },
    },
  );

  const { mutate: unpublishStudy } = useApiMutation(
    "backend",
    (api) => (request: DeleteStudyRequest) => api.unpublishStudy(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(STUDIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          UNPUBLISHED_STUDIES_KEY(toTenantId(header)),
        );
      },
    },
  );

  const actions = useMemo<ActionMenuAction[]>(
    () => [
      {
        action: () => {
          navigate(FrontendPageEnum.ADMIN_STUDY_EDIT.replace(":id", study.id));
        },
        label: { tx: "study.actions.edit" },
      },
      {
        isEnabled: study.isPublished,
        action: () => {
          showModal({
            title: {
              tx: "study.actions.confirmUnpublishTitle",
              txData: {
                studyName: study.headline,
              },
            },
            description: {
              tx: "study.actions.confirmUnpublishText",
              txData: {
                studyName: study.headline,
              },
            },
            onConfirm: () => {
              unpublishStudy({ id: study.id, ...toTenantIdHeader(tenantId) });
              hideModal();
            },
          });
        },
        label: {
          tx: "study.actions.unpublish",
        },
      },
      {
        isEnabled: !study.isPublished,
        action: () => {
          showModal({
            title: {
              tx: "study.actions.confirmPublishTitle",
              txData: {
                studyName: study.headline,
              },
            },
            description: {
              tx: "study.actions.confirmPublishText",
              txData: {
                studyName: study.headline,
              },
            },
            onConfirm: () => {
              publishStudy({ id: study.id, ...toTenantIdHeader(tenantId) });
              hideModal();
            },
          });
        },
        label: {
          tx: "study.actions.publish",
        },
      },
      {
        action: () => {
          showModal({
            title: {
              tx: "study.confirmDeleteTitle",
              txData: {
                studyName: study.headline,
              },
            },
            description: {
              tx: "study.confirmDeleteText",
              txData: {
                studyName: study.headline,
              },
            },
            onConfirm: () => {
              deleteStudy({ id: study.id, ...toTenantIdHeader(tenantId) });
              hideModal();
            },
          });
        },
        label: { tx: "study.actions.delete" },
      },
    ],
    [
      deleteStudy,
      hideModal,
      navigate,
      publishStudy,
      showModal,
      study.headline,
      study.id,
      study.isPublished,
      tenantId,
      unpublishStudy,
    ],
  );

  return (
    <div
      className={twMerge("card bg-base-100 shadow-xl lg:card-side", className)}
      {...rest}
    >
      <figure className="flex flex-col lg:w-1/2">
        <img
          className="aspect-video rounded-br-2xl object-cover object-center"
          src={study.imageLink}
        />
        <div className="flex w-full flex-col gap-2 p-8">
          <h3 className="card-title">{study.headline}</h3>
          <p>{study.description}</p>
        </div>
      </figure>

      {isAdmin && (
        <ActionMenu className="absolute right-4 top-4" actions={actions} />
      )}

      <div
        className={twMerge(
          "card-body max-lg:-mt-8",
          isSignedUp && !disabled ? "justify-center" : "",
        )}
      >
        {isSignedUp && !disabled ? (
          <Text
            as="h3"
            className="card-title self-center justify-self-center text-success"
            tx="study.signUpSuccess"
          />
        ) : (
          <>
            <Text as="h3" className="card-title" tx="study.signUpTitle" />
            <Form
              onSubmit={handleSubmit(() => signUpToStudy(tenantId))}
              className="-mx-2"
            >
              <FormCheckboxInput
                disabled={disabled}
                label={{
                  tx: "study.shareStory.label",
                }}
                {...register("shareStory")}
                error={{ txUnchecked: errors.shareStory?.message }}
              />
              <FormCheckboxInput
                disabled={disabled}
                label={{
                  tx: "study.acceptTerms.label",
                  txComponents: {
                    href: <Link target="_blank" href={study.termsLink} />,
                  },
                }}
                {...register("acceptTerms", {
                  required: tx("study.acceptTerms.required"),
                })}
                error={{ txUnchecked: errors.acceptTerms?.message }}
              />
              <LoadingButton
                isDisabled={disabled}
                type="submit"
                loading={isSubmitting || signUpIsLoading}
                tx="study.signUp"
                className="ml-2 self-start"
              />
            </Form>
          </>
        )}
      </div>
    </div>
  );
};
