import React from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import {
  getTenantSettings,
  organisationsPerDisease,
  TenantIdentifier,
} from "shared/config";
import {
  basePathOfTenant,
  pathSegmentOfDisease,
} from "shared/config/base-url-of-tenant";
import { allDiseaseTypes } from "shared/model/diseases";

import { frontendPages } from "./frontend-pages";
import { isIFramePage } from "./is-page-type";
import {
  AppendEmptyOrganisationToPath,
  NavigateToDisease,
} from "./redirect-utils";
import { TenantPage } from "./tenant-page";
import { getAuthRoutes } from "../auth";
import { NavigateToHome } from "../components/navigate-to-home";
import { Root, TenantPublicRoot, TenantRoot } from "../layouts/root";
import { NavigateToLastActiveTenant } from "../navigate-to-last-active-tenant";
import { IFrameRoute } from "../routes/i-frame-route";
import { NotFound } from "../routes/not-found";
import {
  DiseaseProvider,
  OrganisationProvider,
} from "../util/use-active-tenant-id";

const getTenantPages = (tenantId: TenantIdentifier): RouteObject[] => {
  return [
    {
      path: `${basePathOfTenant(tenantId)}/auth`,
      element: <TenantPublicRoot />,
      children: getAuthRoutes(tenantId),
    },
    {
      path: `${basePathOfTenant(tenantId)}/public`,
      element: <TenantPublicRoot />,
      children: [
        ...getTenantSettings(tenantId).publicPages.map(
          (page): RouteObject => ({
            path: `${basePathOfTenant(tenantId)}/public` + page.route,
            element: isIFramePage(page) ? (
              <IFrameRoute page={{ page, tenantId }} />
            ) : (
              frontendPages[page.route].element
            ),
          }),
        ),
      ],
    },
    {
      path: `${basePathOfTenant(tenantId)}`,
      element: <TenantRoot />,
      children: [
        {
          index: true,
          element: <NavigateToHome />,
        },
        ...getTenantSettings(tenantId).enabledPages.map(
          (page): RouteObject => ({
            path: basePathOfTenant(tenantId) + page.route,
            element: (
              <TenantPage page={page}>
                {isIFramePage(page) ? (
                  <IFrameRoute page={{ page, tenantId }} />
                ) : (
                  frontendPages[page.route].element
                )}
              </TenantPage>
            ),
          }),
        ),
        {
          path: `${basePathOfTenant(tenantId)}/*`,
          element: tenantId.organisation ? (
            <NavigateToDisease />
          ) : (
            <TenantPage>
              <NotFound />
            </TenantPage>
          ),
        },
      ],
    },
  ];
};

export const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      ...allDiseaseTypes.map(
        (disease): RouteObject => ({
          path: `/${pathSegmentOfDisease(disease)}`,
          element: <DiseaseProvider disease={disease} />,
          children: [
            ...[...(organisationsPerDisease.get(disease) ?? [])].map(
              (organisation): RouteObject => ({
                path: `${basePathOfTenant({ disease, organisation })}`,
                element: <OrganisationProvider organisation={organisation} />,
                children: getTenantPages({ disease, organisation }),
              }),
            ),
            ...getTenantPages({ disease }),
            {
              path: `/${pathSegmentOfDisease(disease)}/*`,
              index: true,
              element: <AppendEmptyOrganisationToPath />,
            },
          ],
        }),
      ),
      {
        path: "/*",
        index: true,
        element: <NavigateToLastActiveTenant hasApiAccess={false} />,
      },
      {
        path: "",
        index: true,
        element: <NavigateToLastActiveTenant hasApiAccess={false} />,
      },
    ],
  },
]);
