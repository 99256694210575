export interface TextOnWhatsapp {
  message: string;
  phoneNumber: string;
}

export const supportEmail = "support@mamahealth.io";

export const textOnWhatsapp = ({
  message,
  phoneNumber,
}: TextOnWhatsapp): void => {
  window.open(
    `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
  );
};
