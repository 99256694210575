import { FormCheckboxInput } from "../../../../../components/form/form-checkbox-input";
import { Link } from "../../../../../components/link";
import { FormDataCollectionInput } from "../types";

export const profileVisibilityInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.settings?.agreedToBeingListed,
  Component: ({ errors, register }) => (
    <FormCheckboxInput
      labelClassName="font-bold"
      label={{
        tx: "community.agreedToBeingListedLabel",
        txComponents: {
          href: <Link target="_blank" />,
        },
      }}
      {...register("agreedToBeingListed")}
      error={{ txUnchecked: errors.agreedToBeingListed?.message }}
    />
  ),
};
