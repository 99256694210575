/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageRecord } from './MessageRecord';
import {
    MessageRecordFromJSON,
    MessageRecordFromJSONTyped,
    MessageRecordToJSON,
} from './MessageRecord';

/**
 * 
 * @export
 * @interface ConversationRecord
 */
export interface ConversationRecord {
    /**
     * 
     * @type {string}
     * @memberof ConversationRecord
     */
    id: string;
    /**
     * 
     * @type {Array<MessageRecord>}
     * @memberof ConversationRecord
     */
    messages?: Array<MessageRecord>;
    /**
     * 
     * @type {string}
     * @memberof ConversationRecord
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationRecord
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof ConversationRecord
     */
    inputTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ConversationRecord
     */
    type?: string;
}

/**
 * Check if a given object implements the ConversationRecord interface.
 */
export function instanceOfConversationRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "inputTime" in value;

    return isInstance;
}

export function ConversationRecordFromJSON(json: any): ConversationRecord {
    return ConversationRecordFromJSONTyped(json, false);
}

export function ConversationRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageRecordFromJSON)),
        'language': json['language'],
        'userId': json['userId'],
        'inputTime': (new Date(json['inputTime'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ConversationRecordToJSON(value?: ConversationRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageRecordToJSON)),
        'language': value.language,
        'userId': value.userId,
        'inputTime': (value.inputTime.toISOString()),
        'type': value.type,
    };
}

