"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diseases = void 0;
const atopic_dermatitis_1 = require("./atopic-dermatitis/atopic-dermatitis");
const chronic_hand_eczema_1 = require("./chronic-hand-eczema/chronic-hand-eczema");
const endometriosis_1 = require("./endometriosis/endometriosis");
const long_covid_1 = require("./long-covid/long-covid");
const multiple_sclerosis_1 = require("./multiple-sclerosis/multiple-sclerosis");
const myasthenia_gravis_1 = require("./myasthenia-gravis/myasthenia-gravis");
const opade_1 = require("./opade-study/opade");
const organisations_1 = require("../../model/organisations");
const _diseases = [
    endometriosis_1.endometriosis,
    long_covid_1.longCovid,
    myasthenia_gravis_1.myastheniaGravis,
    multiple_sclerosis_1.multipleSclerosis,
    opade_1.opadeStudy,
    atopic_dermatitis_1.atopicDermatitis,
    chronic_hand_eczema_1.chronicHandEczema,
];
exports.diseases = _diseases.map((disease) => (Object.assign(Object.assign({}, disease), { type: "disease", organisations: disease.organisations.map((organisation) => (0, organisations_1.mergeToOrganisationConfig)(disease, organisation)) })));
