import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, useParams } from "react-router-dom";
import { FrontendPageEnum } from "shared/model/pages";

import {
  CreateStudyDto,
  CreateStudyRequest,
  UpdateStudyRequest,
} from "../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../api/use-api";
import { GhostButton } from "../components/form/button";
import { FormTextInput } from "../components/form/form-text-input";
import { LoadingButton } from "../components/form/loading-button";
import { Study } from "../components/study";
import { tx } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import {
  STUDIES_KEY,
  STUDY_KEY,
  UNPUBLISHED_STUDIES_KEY,
} from "../types/query-keys";
import {
  useNavigateBack,
  useNavigateInsideTenant,
} from "../util/navigation-hooks";
import { isUrlRegex } from "../util/regex";
import { useTenantId } from "../util/use-active-tenant-id";

export const EditStudy: React.FC = () => {
  const tenantId = useTenantId();
  const { id } = useParams();
  const navigateBack = useNavigateBack();
  const navigate = useNavigateInsideTenant();

  const queryClient = useQueryClient();

  const { data: study, isError } = useApiQuery(
    "backend",
    (api) => api.getStudy({ id: id || "" }),
    STUDY_KEY(id),
    undefined,
    {
      enabled: !!id && id !== "new",
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<CreateStudyDto>({ defaultValues: study });

  useEffect(() => {
    reset(study);
  }, [study, reset]);

  const { mutate: updateStudy, isLoading: updateIsLoading } = useApiMutation(
    "backend",
    (api) => (request: UpdateStudyRequest) => api.updateStudy(request),
    undefined,
    { successMessage: { tx: "study.updateSuccessfulMessage" } },
    {
      onSuccess: (updated, header) => {
        reset(updated);
        queryClient.invalidateQueries(STUDIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          UNPUBLISHED_STUDIES_KEY(toTenantId(header)),
        );
        navigate(FrontendPageEnum.ADMIN_STUDIES);
      },
    },
  );

  const { mutate: createNewsStory, isLoading: createIsLoading } =
    useApiMutation(
      "backend",
      (api) => (request: CreateStudyRequest) => api.createStudy(request),
      undefined,
      { successMessage: { tx: "study.createSuccessfulMessage" } },
      {
        onSuccess: (created, header) => {
          reset(created);
          queryClient.invalidateQueries(STUDIES_KEY(toTenantId(header)));
          queryClient.invalidateQueries(
            UNPUBLISHED_STUDIES_KEY(toTenantId(header)),
          );
          navigate(
            FrontendPageEnum.ADMIN_STUDY_EDIT.replace(":id", created.id),
            {
              replace: true,
            },
          );
          navigate(FrontendPageEnum.ADMIN_STUDIES);
        },
      },
    );

  const onSave = handleSubmit(async (data) => {
    if (!id) return;

    if (id !== "new") {
      updateStudy({
        id: id,
        updateStudyDto: data,
        ...toTenantIdHeader(tenantId),
      });
    } else {
      createNewsStory({
        createStudyDto: data,
        ...toTenantIdHeader(tenantId),
      });
    }
  });

  return (
    <DefaultContentContainer>
      <GhostButton
        icon="left"
        tx="navigateBack"
        onClick={() => navigateBack()}
        className="mb-4 pl-0"
      />
      {!isError && (
        <div className="flex flex-row flex-wrap gap-8">
          <Form
            onSubmit={onSave}
            className="min-w-[20rem] max-w-full flex-grow"
          >
            <FormTextInput
              title={{ tx: "study.headline.label" }}
              placeholder={{ tx: "study.headline.placeholder" }}
              {...register("headline", {
                required: tx("study.headline.error"),
              })}
              error={{ txUnchecked: errors.headline?.message }}
            />
            <FormTextInput
              title={{ tx: "study.description.label" }}
              placeholder={{ tx: "study.description.placeholder" }}
              as="textarea"
              {...register("description", {
                required: tx("study.description.error"),
              })}
              error={{ txUnchecked: errors.description?.message }}
            />
            <FormTextInput
              title={{ tx: "study.imageLink.label" }}
              placeholder={{ tx: "study.imageLink.placeholder" }}
              {...register("imageLink", {
                required: tx("study.imageLink.error"),
                pattern: {
                  value: isUrlRegex,
                  message: tx("study.imageLink.validation"),
                },
              })}
              error={{ txUnchecked: errors.imageLink?.message }}
            />
            <FormTextInput
              title={{ tx: "study.termsLink.label" }}
              placeholder={{ tx: "study.termsLink.placeholder" }}
              {...register("termsLink", {
                required: tx("study.termsLink.error"),
                pattern: {
                  value: isUrlRegex,
                  message: tx("study.termsLink.validation"),
                },
              })}
              error={{ txUnchecked: errors.imageLink?.message }}
            />
            <LoadingButton
              type="submit"
              loading={isSubmitting || updateIsLoading || createIsLoading}
              tx="editNews.save"
              className="ml-2 self-start"
            />
          </Form>

          <Study
            className="w-full"
            study={{
              createdAt: new Date(),
              id: "",
              ...watch(),
              updatedAt: new Date(),
              isPublished: false,
            }}
            disabled={true}
          />
        </div>
      )}
    </DefaultContentContainer>
  );
};
