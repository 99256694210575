/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateStudyDto
 */
export interface CreateStudyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStudyDto
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudyDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudyDto
     */
    imageLink: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudyDto
     */
    termsLink: string;
}

/**
 * Check if a given object implements the CreateStudyDto interface.
 */
export function instanceOfCreateStudyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "headline" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageLink" in value;
    isInstance = isInstance && "termsLink" in value;

    return isInstance;
}

export function CreateStudyDtoFromJSON(json: any): CreateStudyDto {
    return CreateStudyDtoFromJSONTyped(json, false);
}

export function CreateStudyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStudyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headline': json['headline'],
        'description': json['description'],
        'imageLink': json['imageLink'],
        'termsLink': json['termsLink'],
    };
}

export function CreateStudyDtoToJSON(value?: CreateStudyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headline': value.headline,
        'description': value.description,
        'imageLink': value.imageLink,
        'termsLink': value.termsLink,
    };
}

