/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Gender } from './Gender';
import {
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a UserAttributes record
 * @export
 * @interface UserAttributes
 */
export interface UserAttributes {
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof UserAttributes
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    finishedSignUp: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    finishedSignUpViaCall: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    language?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAttributes
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    watchedOnboardingVideo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    hasSeenAllCards?: boolean;
    /**
     * 
     * @type {Gender}
     * @memberof UserAttributes
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    migrationBackground?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    acquisitionSource?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    acquisitionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    utmContent?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    opadePatientId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    hometown?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    currentTown?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    lookingForOnMamaHealth?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    lookingForOthersOnMamaHealth?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    agreedToEndoStudyInformation20240115?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    agreedToPrivacyPolicy20231018?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    agreedToTermsAndConditions20230830?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    telephone?: string;
}

/**
 * Check if a given object implements the UserAttributes interface.
 */
export function instanceOfUserAttributes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "finishedSignUp" in value;
    isInstance = isInstance && "finishedSignUpViaCall" in value;

    return isInstance;
}

export function UserAttributesFromJSON(json: any): UserAttributes {
    return UserAttributesFromJSONTyped(json, false);
}

export function UserAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'finishedSignUp': json['finishedSignUp'],
        'finishedSignUpViaCall': json['finishedSignUpViaCall'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'watchedOnboardingVideo': !exists(json, 'watchedOnboardingVideo') ? undefined : json['watchedOnboardingVideo'],
        'hasSeenAllCards': !exists(json, 'hasSeenAllCards') ? undefined : json['hasSeenAllCards'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'education': !exists(json, 'education') ? undefined : json['education'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'migrationBackground': !exists(json, 'migrationBackground') ? undefined : json['migrationBackground'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'acquisitionSource': !exists(json, 'acquisitionSource') ? undefined : json['acquisitionSource'],
        'acquisitionDetail': !exists(json, 'acquisitionDetail') ? undefined : json['acquisitionDetail'],
        'utmSource': !exists(json, 'utmSource') ? undefined : json['utmSource'],
        'utmCampaign': !exists(json, 'utmCampaign') ? undefined : json['utmCampaign'],
        'utmContent': !exists(json, 'utmContent') ? undefined : json['utmContent'],
        'opadePatientId': !exists(json, 'opadePatientId') ? undefined : json['opadePatientId'],
        'hometown': !exists(json, 'hometown') ? undefined : json['hometown'],
        'currentTown': !exists(json, 'currentTown') ? undefined : json['currentTown'],
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'lookingForOnMamaHealth': !exists(json, 'lookingForOnMamaHealth') ? undefined : json['lookingForOnMamaHealth'],
        'lookingForOthersOnMamaHealth': !exists(json, 'lookingForOthersOnMamaHealth') ? undefined : json['lookingForOthersOnMamaHealth'],
        'agreedToEndoStudyInformation20240115': !exists(json, 'agreedToEndoStudyInformation20240115') ? undefined : json['agreedToEndoStudyInformation20240115'],
        'agreedToPrivacyPolicy20231018': !exists(json, 'agreedToPrivacyPolicy20231018') ? undefined : json['agreedToPrivacyPolicy20231018'],
        'agreedToTermsAndConditions20230830': !exists(json, 'agreedToTermsAndConditions20230830') ? undefined : json['agreedToTermsAndConditions20230830'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
    };
}

export function UserAttributesToJSON(value?: UserAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'finishedSignUp': value.finishedSignUp,
        'finishedSignUpViaCall': value.finishedSignUpViaCall,
        'language': value.language,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString()),
        'watchedOnboardingVideo': value.watchedOnboardingVideo,
        'hasSeenAllCards': value.hasSeenAllCards,
        'gender': GenderToJSON(value.gender),
        'education': value.education,
        'ethnicity': value.ethnicity,
        'migrationBackground': value.migrationBackground,
        'country': value.country,
        'city': value.city,
        'address': value.address,
        'postalCode': value.postalCode,
        'acquisitionSource': value.acquisitionSource,
        'acquisitionDetail': value.acquisitionDetail,
        'utmSource': value.utmSource,
        'utmCampaign': value.utmCampaign,
        'utmContent': value.utmContent,
        'opadePatientId': value.opadePatientId,
        'hometown': value.hometown,
        'currentTown': value.currentTown,
        'occupation': value.occupation,
        'introduction': value.introduction,
        'avatarUrl': value.avatarUrl,
        'lookingForOnMamaHealth': value.lookingForOnMamaHealth,
        'lookingForOthersOnMamaHealth': value.lookingForOthersOnMamaHealth,
        'agreedToEndoStudyInformation20240115': value.agreedToEndoStudyInformation20240115,
        'agreedToPrivacyPolicy20231018': value.agreedToPrivacyPolicy20231018,
        'agreedToTermsAndConditions20230830': value.agreedToTermsAndConditions20230830,
        'name': value.name,
        'email': value.email,
        'telephone': value.telephone,
    };
}

