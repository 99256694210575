import React from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { GhostButton } from "../components/form/button";
import { SectionHeading, SingleColumnSection } from "../components/gallery";
import { Study } from "../components/study";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { STUDIES_KEY, UNPUBLISHED_STUDIES_KEY } from "../types/query-keys";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export const Studies: React.FC<{ isAdmin?: boolean }> = ({
  isAdmin = false,
}) => {
  const tenantId = useTenantId();
  const navigate = useNavigateInsideTenant();

  const { data: studies } = useApiQuery(
    "backend",
    (api) => api.listStudies(toTenantIdHeader(tenantId)),
    STUDIES_KEY(tenantId),
  );

  const { data: unpublishedStudies } = useApiQuery(
    "backend",
    (api) => api.listUnpublishedStudies(toTenantIdHeader(tenantId)),
    UNPUBLISHED_STUDIES_KEY(tenantId),
    undefined,
    { enabled: isAdmin },
  );

  return (
    <DefaultContentContainer className="flex flex-col gap-4">
      {isAdmin && (
        <>
          <SectionHeading tx="study.unpublishedHeading" className="relative">
            <GhostButton
              className="btn-circle absolute right-1"
              icon="plus"
              onClick={() =>
                navigate(
                  FrontendPageEnum.ADMIN_STUDY_EDIT.replace(":id", "new"),
                )
              }
            />
          </SectionHeading>
          <SingleColumnSection>
            {unpublishedStudies?.map((study) => (
              <Study key={study.id} study={study} isAdmin={isAdmin} />
            ))}
          </SingleColumnSection>
          {unpublishedStudies?.length === 0 && (
            <Text tx="study.noUnpublishedStudies" className="text-center" />
          )}

          <SectionHeading tx="study.publishedHeading" className="mt-8" />
        </>
      )}
      <div className="flex flex-col gap-8">
        {studies?.map((study) => (
          <Study study={study} key={study.id} isAdmin={isAdmin} />
        ))}
      </div>

      {studies?.length === 0 && (
        <Text tx="study.noneAvailable" className="text-center" />
      )}
    </DefaultContentContainer>
  );
};
