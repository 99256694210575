"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertInputsAtIndex = exports.insertInputs = exports.pushInputs = exports.FormDataCollectionInputType = exports.FormDataGuardLayoutType = void 0;
var FormDataGuardLayoutType;
(function (FormDataGuardLayoutType) {
    FormDataGuardLayoutType["SIGN_UP_LAYOUT"] = "SIGN_UP_LAYOUT";
    FormDataGuardLayoutType["EXISTING_USER_LAYOUT"] = "EXISTING_USER_LAYOUT";
})(FormDataGuardLayoutType || (exports.FormDataGuardLayoutType = FormDataGuardLayoutType = {}));
var FormDataCollectionInputType;
(function (FormDataCollectionInputType) {
    FormDataCollectionInputType["NAME"] = "NAME";
    FormDataCollectionInputType["EMAIL"] = "EMAIL";
    FormDataCollectionInputType["PHONE_NUMBER"] = "PHONE_NUMBER";
    FormDataCollectionInputType["PRIVACY_POLICY_2023_10_18"] = "PRIVACY_POLICY_2023_10_18";
    FormDataCollectionInputType["TERMS_AND_CONDITIONS_2023_08_30"] = "TERMS_AND_CONDITIONS_2023_08_30";
    FormDataCollectionInputType["ENDO_STUDY_INFORMATION_2024_01_15"] = "ENDO_STUDY_INFORMATION_2024_01_15";
    FormDataCollectionInputType["OPADE_PATIENT_ID"] = "OPADE_PATIENT_ID";
    FormDataCollectionInputType["LANGUAGE"] = "LANGUAGE";
    FormDataCollectionInputType["DATE_OF_BIRTH"] = "DATE_OF_BIRTH";
    FormDataCollectionInputType["GENDER"] = "GENDER";
    FormDataCollectionInputType["ADDRESS"] = "ADDRESS";
    FormDataCollectionInputType["CITY"] = "CITY";
    FormDataCollectionInputType["POSTAL_CODE"] = "POSTAL_CODE";
    FormDataCollectionInputType["COUNTRY"] = "COUNTRY";
    FormDataCollectionInputType["ACQUISITION_SOURCE"] = "ACQUISITION_SOURCE";
    FormDataCollectionInputType["ACQUISITION_DETAIL"] = "ACQUISITION_DETAIL";
    FormDataCollectionInputType["MEDICATIONS"] = "MEDICATIONS";
    FormDataCollectionInputType["CONDITION_START_DATE"] = "CONDITION_START_DATE";
    FormDataCollectionInputType["CARE_PROVIDER"] = "CARE_PROVIDER";
    FormDataCollectionInputType["CONDITION_VARIANT"] = "CONDITION_VARIANT";
    FormDataCollectionInputType["AGREED_TO_BEING_LISTED"] = "AGREED_TO_BEING_LISTED";
    FormDataCollectionInputType["PROFILE_IMAGE"] = "PROFILE_IMAGE";
    FormDataCollectionInputType["IS_MEMBER_OF_PAG"] = "IS_MEMBER_OF_PAG";
})(FormDataCollectionInputType || (exports.FormDataCollectionInputType = FormDataCollectionInputType = {}));
const pushInputs = (dataGuard, ...newInputs) => (Object.assign(Object.assign({}, dataGuard), { inputs: [...dataGuard.inputs, ...newInputs] }));
exports.pushInputs = pushInputs;
const insertInputs = (dataGuard, ...newInputs) => (Object.assign(Object.assign({}, dataGuard), { inputs: [...newInputs, ...dataGuard.inputs] }));
exports.insertInputs = insertInputs;
const insertInputsAtIndex = (config, ...newInputs) => (Object.assign(Object.assign({}, config.dataGuard), { inputs: [
        ...config.dataGuard.inputs.slice(0, config.index),
        ...newInputs,
        ...config.dataGuard.inputs.slice(config.index),
    ] }));
exports.insertInputsAtIndex = insertInputsAtIndex;
