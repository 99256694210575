import { Link as ReactLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { I18nProps, Text } from "./text";

export type LinkProps = Omit<JSX.IntrinsicElements["a"], "ref"> & I18nProps;

export const Link: React.FC<LinkProps> = ({
  className,
  children,
  text,
  tx,
  txComponents,
  txData,
  href,
  ...rest
}) => {
  const Element = isRelativeUrl(href) ? ReactLink : "a";
  return (
    <Element
      to={href as string}
      href={href}
      className={twMerge("link link-primary no-underline", className)}
      {...rest}
    >
      <Text tx={tx} text={text} txComponents={txComponents} txData={txData} />
      {children}
    </Element>
  );
};

const isRelativeUrl = (href?: string): boolean => {
  if (!href) {
    return false;
  }
  try {
    new URL(href);
    return false;
  } catch {
    return true;
  }
};
