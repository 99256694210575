import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGuard as DataGuardConfig } from "shared/model/data-collection/guard";
import {
  createDataGuard,
  DataGuardType,
} from "shared/model/data-collection/guard/data-guard-type";

import { dataGuardImplemenetations } from "./implementations/data-guard-implementations";
import { User, UserTenantGroupsEnum } from "../../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { LoadingScreen } from "../../components/loading-screen";
import { useTenantConfig } from "../../tenant-settings";
import { ME_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { DataGuardFullscreenContainer } from "../container/fullscreen";
import { DataGuardModalContainer } from "../container/modal";
import { DataGuardContainer, DataGuardRoutesType } from "../container/types";

export const DataGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const tenantId = useTenantId();
  const config = useTenantConfig();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );
  const userIsAdmin = useMemo(
    () => user?.tenantGroups.includes(UserTenantGroupsEnum.Admin),
    [user],
  );
  const userFinishedSignUpInThePast = useMemo(
    () => user?.userAttributes?.finishedSignUp ?? false,
    [user],
  );

  const dataGuards = userIsAdmin
    ? userFinishedSignUpInThePast
      ? config.dataGuards.existingAdmin
      : config.dataGuards.signUpAdmin
    : userFinishedSignUpInThePast
    ? config.dataGuards.existingUser
    : config.dataGuards.signUpUser;

  const blankGuard = createDataGuard({
    path: "platform",
    type: DataGuardType.BLANK_GUARD,
    title: "dataGuards.platform",
  });

  const Container = userFinishedSignUpInThePast
    ? DataGuardModalContainer
    : DataGuardFullscreenContainer;

  return user ? (
    <DataGuardRouter
      user={user}
      key={`${tenantId.disease}_${userIsAdmin}_${userFinishedSignUpInThePast}`}
      dataGuards={[...dataGuards, blankGuard]}
      Container={Container}
    >
      {children}
    </DataGuardRouter>
  ) : (
    <LoadingScreen />
  );
};

const DataGuardRouter: React.FC<
  PropsWithChildren<{
    user: User;
    dataGuards: DataGuardConfig[];
    Container: DataGuardContainer;
  }>
> = ({ children, dataGuards, Container, user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dataGuardsWereCompleted = dataGuards.map((guard) =>
    dataGuardImplemenetations[guard?.type].useWasCompleted(guard),
  );

  const loading = dataGuardsWereCompleted.some(
    (guardCompleted) => guardCompleted === undefined,
  );

  const numberOfGuardsCompleted = useMemo(
    () =>
      dataGuardsWereCompleted.findIndex((guardCompleted) => !guardCompleted),
    [dataGuardsWereCompleted],
  );

  const groupDoesNotRequireDataCollection = useMemo(
    () =>
      user.tenantGroups.some(
        (group) =>
          group === UserTenantGroupsEnum.Pharma ||
          group === UserTenantGroupsEnum.Pag,
      ),
    [user.tenantGroups],
  );

  const hasFinishedSignup =
    groupDoesNotRequireDataCollection ||
    numberOfGuardsCompleted === dataGuards.length ||
    numberOfGuardsCompleted === -1;

  const [activeIndex, setActiveIndex] = useState(numberOfGuardsCompleted);

  const dataGuardRoutes: DataGuardRoutesType = useMemo(
    () =>
      Object.fromEntries(
        dataGuards.map((guard, index) => [
          index,
          {
            route: guard.path,
          },
        ]),
      ),
    [dataGuards],
  );

  const goToGuard = useCallback(
    (index: number) => {
      if (numberOfGuardsCompleted >= index) {
        navigate(dataGuardRoutes[index]?.route);
      }
    },
    [dataGuardRoutes, navigate, numberOfGuardsCompleted],
  );

  const dataGuardComponents = useMemo(
    () =>
      dataGuards.map((guard, index) =>
        React.createElement(
          dataGuardImplemenetations[dataGuards[index]?.type]?.Component,
          {
            key: index,
            config: {
              ...guard,
              goToGuard,
              currentGuard: activeIndex,
            },
          },
        ),
      ),
    [activeIndex, dataGuards, goToGuard],
  );

  useEffect(() => {
    Object.entries(dataGuardRoutes).forEach(([key, value]) => {
      if (location.pathname.includes(value.route)) {
        setActiveIndex(parseInt(key));
      }
    });
  }, [dataGuardRoutes, location.pathname]);

  useEffect(() => {
    if (!hasFinishedSignup) {
      navigate(dataGuardRoutes[numberOfGuardsCompleted]?.route);
    }
  }, [dataGuardRoutes, hasFinishedSignup, navigate, numberOfGuardsCompleted]);

  return (
    <Container
      DataGuardConfig={
        loading
          ? {
              titles: [],
              dataGuards,
              activeIndex: -1,
              navigateToIndex: goToGuard,
              dataGuardComponents: [],
              numberOfGuardsCompleted,
              dataGuardRoutes: {},
            }
          : !hasFinishedSignup
          ? {
              titles: dataGuards.map((guard) => ({ tx: guard.title })),
              activeIndex,
              dataGuards,
              navigateToIndex: goToGuard,
              dataGuardRoutes,
              dataGuardComponents,
              numberOfGuardsCompleted,
            }
          : undefined
      }
    >
      {children}
    </Container>
  );
};
