"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCards = void 0;
exports.defaultCards = [
    {
        title: "dataGuards.cardGuard.title.welcomeToMamaHealth",
        description: "dataGuards.cardGuard.description.joinUs",
        image: "dataGuards.cardGuard.image.image_1",
    },
    {
        title: "dataGuards.cardGuard.title.timeToShareStory",
        description: "dataGuards.cardGuard.description.describeWhat",
        image: "dataGuards.cardGuard.image.image_2",
    },
    {
        title: "dataGuards.cardGuard.title.youKnow",
        description: "dataGuards.cardGuard.description.moreYouShare",
        image: "dataGuards.cardGuard.image.image_3",
    },
];
