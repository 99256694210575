import { useMemo } from "react";

import { useApiQuery } from "../api/use-api";
import { EmptyJourney } from "../components/empty-journey";
import { PrintableJourney } from "../components/printable-journey";
import { GET_EVENTS_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

const range = [
  200, // start_angle
  10, // distance_per_step
];

export const Journey: React.FC = () => {
  const { disease } = useTenantId();

  const { data: events } = useApiQuery(
    "multiagent",
    (api) => api.getEvents({ mamaDisease: disease }),
    GET_EVENTS_KEY(disease),
  );

  const history = useMemo(() => events?.events ?? [], [events]);
  const userDetails = useMemo(() => events?.userDetails ?? [], [events]);

  const colors = useMemo(() => {
    const targetLength = (history?.length ?? 0) + 2;
    return Array.from(Array(targetLength).keys()).map(
      (i: number) =>
        `hsl(${Math.trunc(
          range[0] + ((range[1] * targetLength) / targetLength) * i,
        )}, 60%, 70%)`,
    );
  }, [history]);

  return events && (history.length || userDetails.length) ? (
    <PrintableJourney
      userDetails={userDetails}
      timeline={history}
      colors={colors}
    />
  ) : (
    <EmptyJourney />
  );
};
