import React, { useMemo } from "react";
import { EnabledPage } from "shared/model/pages";

import { Text, TranslationFeKey } from "./text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { pageLockImplementations } from "../page-lock/page-lock";

export const LockGuard: React.FC<{
  page?: EnabledPage;
  children: React.ReactNode;
}> = ({ children, page }) => {
  const { isLocked, reasonWhyLocked } = useIsPageLocked(page);

  return isLocked ? (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      <Text as="div" className="text-2xl" tx={reasonWhyLocked} />
    </DefaultContentContainer>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const useIsPageLocked = (
  page?: EnabledPage,
): { isLocked: boolean; reasonWhyLocked?: TranslationFeKey } => {
  if (!page) return { isLocked: false };

  const locksMap = new Map<string, boolean>();
  for (const [key, value] of Object.entries(pageLockImplementations)) {
    locksMap.set(key, value(page));
  }

  const isLocked = !page.pageLock.every(
    (lock) => !!locksMap.get(lock.lockType),
  );

  const lockedPageIndex = page.pageLock.findIndex(
    (lock) => !locksMap.get(lock.lockType),
  );

  return useMemo(
    () => ({
      isLocked,
      reasonWhyLocked:
        lockedPageIndex === -1
          ? undefined
          : page.pageLock[lockedPageIndex].reasonWhyLocked,
    }),
    [isLocked, lockedPageIndex, page.pageLock],
  );
};
