"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.followUpUserFeedbacks = exports.defaultUserFeedbacks = void 0;
const user_feedback_type_1 = require("../../types/user-feedback-type");
exports.defaultUserFeedbacks = [
    // {
    //   feedbackId: "bf07237a-7cdb-4846-bcb5-c0281288b241",
    //   activityMinutes: 20,
    //   type: UserFeedbackType.RATING,
    //   title: "userFeedback.questions.howDoYouEnjoyOurPlatform",
    // },
    {
        feedbackId: "6dfcc68e-3c0b-4da9-8369-5c965ffdc17f",
        activityMinutes: 20,
        type: user_feedback_type_1.UserFeedbackType.SCALE,
        title: "userFeedback.questions.leaveUsAComment",
    },
    {
        feedbackId: "184c13ea-b340-4dea-b30e-3267dbcc3257",
        activityMinutes: 20,
        type: user_feedback_type_1.UserFeedbackType.SATISFACTION,
        title: "userFeedback.questions.howLikelyAreYouToRecommend",
    },
];
exports.followUpUserFeedbacks = [
    // {
    //   feedbackId: "9ed033a3-aed0-4d05-a1c9-f0d8f4064d5a",
    //   activityMinutes: 60,
    //   type: UserFeedbackType.RATING,
    //   title: "userFeedback.questions.howDoYouEnjoyOurPlatformFollowUp",
    // },
    {
        feedbackId: "aa8107d5-ff1e-4836-bb47-6d87782632d0",
        activityMinutes: 60,
        type: user_feedback_type_1.UserFeedbackType.SCALE,
        title: "userFeedback.questions.leaveUsACommentFollowUp",
    },
    {
        feedbackId: "5200c000-1602-4e98-bbe3-e46dea1165f1",
        activityMinutes: 60,
        type: user_feedback_type_1.UserFeedbackType.SATISFACTION,
        title: "userFeedback.questions.howLikelyAreYouToRecommendFollowUp",
    },
];
