import { useCallback, useMemo } from "react";

import { Dropdown, DropdownProps } from "./form/dropdown";
import { useAuthStore } from "../auth/auth-store-context";
import { useProfile } from "../data-collection/profile/profile";

enum Settings {
  PROFILE = "PROFILE",
  SIGN_OUT = "SIGN_OUT",
}

const options: DropdownProps<Settings>["options"] = [
  {
    value: Settings.PROFILE,
    label: { tx: "profile.headerTitle" },
  },
  {
    value: Settings.SIGN_OUT,
    label: { tx: "auth.signOut.signOutButton" },
  },
];

export const SettingsDropdown: React.FC<{
  isMobileView?: boolean;
}> = ({ isMobileView }) => {
  const { showProfile } = useProfile();
  const { signOut } = useAuthStore();

  const optionsActionMap: Record<Settings, () => void> = useMemo(
    () => ({
      [Settings.PROFILE]: showProfile,
      [Settings.SIGN_OUT]: signOut ?? (() => {}),
    }),
    [signOut, showProfile],
  );

  const onChangeDropdownValue = useCallback(
    (value: Settings) => {
      optionsActionMap[value]();
    },
    [optionsActionMap],
  );

  return (
    <Dropdown
      noValueSelected={{ tx: "general.settings" }}
      options={options}
      onChange={onChangeDropdownValue}
      className={isMobileView ? "dropdown-top" : undefined}
    />
  );
};
