/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Study } from './Study';
import {
    StudyFromJSON,
    StudyFromJSONTyped,
    StudyToJSON,
} from './Study';

/**
 * 
 * @export
 * @interface StudySignUp
 */
export interface StudySignUp {
    /**
     * 
     * @type {string}
     * @memberof StudySignUp
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudySignUp
     */
    studyId: string;
    /**
     * 
     * @type {Study}
     * @memberof StudySignUp
     */
    study: Study;
    /**
     * 
     * @type {string}
     * @memberof StudySignUp
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof StudySignUp
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudySignUp
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the StudySignUp interface.
 */
export function instanceOfStudySignUp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "studyId" in value;
    isInstance = isInstance && "study" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function StudySignUpFromJSON(json: any): StudySignUp {
    return StudySignUpFromJSONTyped(json, false);
}

export function StudySignUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudySignUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'studyId': json['studyId'],
        'study': StudyFromJSON(json['study']),
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function StudySignUpToJSON(value?: StudySignUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studyId': value.studyId,
        'study': StudyToJSON(value.study),
        'userId': value.userId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

