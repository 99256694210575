import React, { useCallback, useState } from "react";
import { defaultCards } from "shared/model/data-collection/guard/card-data-guard/default-cards";
import { twMerge } from "tailwind-merge";

import { useT } from "../../../../../i18n/use-t";
import { useModal } from "../../../../../models/modal-provider";
import {
  GhostButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../../form/button";
import { Text } from "../../../../text";
import { ChatOptionComponentProps } from "../lookup";

export const IntroductionModal: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const t = useT();
  const { showModal, hideModal } = useModal();

  const toggleModal = useCallback(() => {
    showModal({
      confirmButtonTx: "chat.accept",
      onConfirm: () => {
        sendResponse({
          message: t({ tx: "chat.accept" }),
          selection: [],
        });
        hideModal();
      },
      children: <IntroductionModalChildren />,
    });
  }, [hideModal, sendResponse, showModal, t]);

  return (
    <div className="flex gap-3">
      <SecondaryButton tx="chat.showMore" onClick={toggleModal} />
      <PrimaryButton
        tx="chat.skip"
        onClick={() => {
          sendResponse({ message: t({ tx: "chat.skip" }), selection: [] });
        }}
      />
    </div>
  );
};

const IntroductionModalChildren: React.FC = () => {
  const t = useT();
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  return (
    <>
      {defaultCards.map(
        (card, idx) =>
          activeCardIndex === idx && (
            <div
              className="flex max-w-lg flex-col items-center gap-5"
              key={card.title}
            >
              <Text className="text-2xl md:text-4xl" tx={card.title} />
              <img
                src={t({ tx: card.image })}
                className="h-64 md:h-80 lg:h-96"
              />
              <Text
                tx={card.description}
                className="text-center text-xl md:text-2xl"
              />

              <div className="flex gap-3">
                <GhostButton
                  text="<"
                  className={twMerge(
                    "h-12 w-12 rounded-full border-2 border-slate-300",
                    activeCardIndex > 0
                      ? "inherit"
                      : "pointer-events-none opacity-0",
                  )}
                  onClick={() => setActiveCardIndex(activeCardIndex - 1)}
                />

                <GhostButton
                  text=">"
                  className={twMerge(
                    "h-12 w-12 rounded-full border-2",
                    activeCardIndex === defaultCards.length - 1
                      ? "pointer-events-none opacity-0"
                      : "border-neutral",
                  )}
                  onClick={() => {
                    activeCardIndex < defaultCards.length - 1
                      ? setActiveCardIndex(activeCardIndex + 1)
                      : undefined;
                  }}
                />
              </div>
            </div>
          ),
      )}
    </>
  );
};
