import { QueryKey } from "@tanstack/react-query";
import { TenantIdentifier } from "shared/config";
import { Disease } from "shared/model/diseases";
import { Language } from "shared/model/languages";
import { FrontendPageEnum } from "shared/model/pages";

export type MamaQueryKey = QueryKey & Array<unknown>;

export type QueryKeyFn<Params extends Array<unknown> = [string | undefined]> = (
  ...params: Params
) => MamaQueryKey;

export const perTenant =
  <Params extends Array<unknown> = []>(
    key: MamaQueryKey | QueryKeyFn<Params>,
  ): QueryKeyFn<[TenantIdentifier, ...Params]> =>
  (tenantId, ...params) =>
    [
      ...(Array.isArray(key) ? key : key(...params)),
      `${tenantId.disease}`,
      `${tenantId?.organisation}`,
    ];

export const perDisease =
  <Params extends Array<unknown> = []>(
    key: MamaQueryKey | QueryKeyFn<Params>,
  ): QueryKeyFn<[Disease, ...Params]> =>
  (disease, ...params) =>
    [...(Array.isArray(key) ? key : key(...params)), `${disease}`];

export const perLanguage =
  <Params extends Array<unknown> = []>(
    key: MamaQueryKey | QueryKeyFn<Params>,
  ): QueryKeyFn<[Language, ...Params]> =>
  (language, ...params) =>
    [...(Array.isArray(key) ? key : key(...params)), `${language}`];

export const ME_KEY = perTenant(["me"]);
export const FOLLOWED_PAGS_KEY = perDisease(["followed-pags"]);
export const HAS_EVENTS = perDisease(["has-events"]);
export const TENANT_SETTINGS_KEY = perTenant(["tenant_settings"]);
export const LAST_ACTIVE_TENANT_KEY: MamaQueryKey = [
  "last_active_tenant",
  location,
];
export const PRODUCT_PRICES_KEY = perTenant(["product_prices"]);
export const RETRIEVE_ACCOUNT_KEY = perTenant(["retrieve_account"]);
export const CONNECT_ACCOUNT_KEY = perTenant(["connect_account"]);
export const PAYMENT_INTENT_KEY = perTenant(["payment_intent"]);

export const CUSTOM_ATTRIBUTES_KEY: MamaQueryKey = ["custom_attributes"];
export const PAYMENT_METHODS_KEY = perTenant(["payment_methods"]);
export const STRIPE_CUSTOMER_KEY = perTenant(["stripe_customer"]);
export const USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY = perTenant([
  "user_owned_active_subscriptions",
]);
export const USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY = perTenant([
  "user_owned_scheduled_subscriptions",
]);
export const GET_LOCALIZED_EVENTS_KEY = perDisease(["translated_events"]);
export const GET_EVENTS_KEY = perDisease(["events"]);
export const NOTIFICATION_KEY = perDisease(["notification"]);

export const NEWS_STORIES_KEY = perTenant(["news"]);
export const NEWS_STORY_DRAFTS_KEY = perTenant(["news", "drafts"]);
export const NEWS_STORY_PINNED_KEY = perTenant(["news", "pinned"]);
export const NEWS_STORY_KEY: QueryKeyFn = (id?: string) => ["news", id];
export const CONVERSATIONS_KEY = perDisease(["conversations"]);
export const CONVERSATION_KEY = perDisease((id?: string) => [
  "conversations",
  id,
]);
export const START_PUBLIC_CONVERSATION_KEY = perDisease((id?: string) => [
  "start-public-conversation",
  id,
]);
export const PUBLIC_CHAT_CONVERSATION_KEY = perDisease((id?: string) => [
  "public-chat-conversation",
  id,
]);
export const CHAT_COMPLETENESS_KEY = perDisease(["chat_completion"]);
export const CHAT_MESSAGE_COUNT_KEY = perDisease(["chat_message_count"]);
export const LANDBOT_CHAT_EVENT_COMPLETENESS_KEY = perDisease([
  "landbot_chat_event_completion",
]);
export const LANDBOT_CHAT_COMPLETENESS_KEY = perDisease([
  "landbot_chat_completion",
]);
export const STUDIES_KEY = perTenant(["studies"]);
export const UNPUBLISHED_STUDIES_KEY = perTenant(["unpublished_studies"]);
export const STUDY_KEY: QueryKeyFn = (id?: string) => ["studies", id];

export const IS_USER_FOLLOWING_TENANT_KEY = perTenant([
  "is_user_following_tenant",
]);

export const SHARED_SESSION_TOKEN_FOR_FORUM_KEY = perDisease(() => [
  "get_shared_session_token_dto",
]);

export const SIGNED_IFRAMEURL_FOR_METABASE_DASHBOARD_KEY = (
  page: FrontendPageEnum,
): QueryKeyFn<[TenantIdentifier]> =>
  perTenant(["metabase_dashboard_signed_token", page]);

export const SOCIAL_MEDIA_POSTS = perTenant(["social_media_posts"]);
export const SOCIAL_MEDIA_COMMENTS = perTenant(["social_media_comments"]);
export const SOCIAL_MEDIA_POST_CATEGORIES = perLanguage([
  "social_media_post_categories",
]);

export const SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL: MamaQueryKey = [
  "social_media_post_categories",
];
export const USER_EXISTS_KEY: MamaQueryKey = ["user_exists"];
export const AUTH_JWT_TOKEN_QUERY_KEY: MamaQueryKey = ["auth_jwt_token"];
export const USER_DETAILS_KEY: MamaQueryKey = ["user_details"];
export const COUNTRY_AND_CODE: MamaQueryKey = ["country_and_code"];
export const COORDINATES: MamaQueryKey = ["coordinates"];
export const ADDRESS: MamaQueryKey = ["address"];
export const SOCIAL_MEDIA_POST: MamaQueryKey = ["social_media_post"];
export const SOCIAL_MEDIA_MEDIA: QueryKeyFn = (id?: string) => [
  "social_media_media",
  id,
];

export const USER: QueryKeyFn = (id?: string) => ["user", id];

export const MY_DOCTORS = perDisease(["my_doctors"]);
export const LINK_DOCTOR = perDisease(["link_doctor"]);
export const RATE_DOCTOR: MamaQueryKey = ["rate-doctor"];
export const ASK_FEEDBACK: MamaQueryKey = ["ask-feedback"];
export const SUBMIT_FEEDBACK: MamaQueryKey = ["submit-feedback"];
export const CREATE_FEEDBACK: MamaQueryKey = ["create-feedback"];
export const DISMISS_FEEDBACK: MamaQueryKey = ["dismiss-feedback"];
