import { useEffect } from "react";
import { tenantIdEquals } from "shared/config";

import { LoadingScreen } from "./loading-screen";
import { useTenantPages } from "../router/use-tenant-pages";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export const NavigateToHome: React.FC = () => {
  const navigate = useNavigateInsideTenant();
  const tenantId = useTenantId();
  const tenantPages = useTenantPages();

  useEffect(() => {
    const enabledPages = tenantPages
      ?.filter((page) => tenantIdEquals(page.tenantId, tenantId))
      .map(({ page }) => page);
    if (enabledPages && enabledPages.length > 0) {
      navigate(enabledPages[0].route, { replace: true });
    }
  }, [navigate, tenantId, tenantPages]);
  return <LoadingScreen />;
};
