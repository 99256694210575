import { CommunityUser } from "../../api/generated/backend";

export type CommunityMembersFilter = {
  query: string;
  ageFrom?: number | null;
  ageTo?: number | null;
  city: string;
};

export const applyQuery = (
  communityUsers: CommunityUser[],
  filters: CommunityMembersFilter,
): CommunityUser[] => {
  const toLowerCaseQuery = filters.query?.toLowerCase();
  const toLowserCaseCity = filters.city.toLowerCase();
  if (!filters) return communityUsers;
  let result: CommunityUser[] = communityUsers;

  if (toLowerCaseQuery)
    result = result.filter((user) => {
      return [
        user.name?.toLowerCase(),
        user.email?.toLowerCase(),
        user.age?.toString(),
        user.hometown?.toLowerCase(),
        user.currentTown?.toLowerCase(),
      ].some((value) => value?.includes(toLowerCaseQuery));
    });
  if (filters.ageFrom)
    result = result.filter(
      (user) =>
        user.age?.toString() && filters.ageFrom && user.age >= filters.ageFrom,
    );
  if (filters.ageTo)
    result = result.filter(
      (user) =>
        user.age?.toString() && filters.ageTo && user.age <= filters.ageTo,
    );
  if (toLowserCaseCity)
    result = result.filter((user) => user.hometown?.includes(toLowserCaseCity));
  return result;
};
