import { useEffect, useState } from "react";
import { SECONDS_MAGIC_LINK_COOLDOWN } from "shared/utils/constants";

import { useT } from "../../../../i18n/use-t";
import { Button } from "../../../form/button";
import { Text } from "../../../text";

interface SignUpDrawerProps {
  hasInitialTimeout: boolean;
  reTriggerEmail: () => void;
}

export const SignUpDrawer: React.FC<SignUpDrawerProps> = ({
  hasInitialTimeout,
  reTriggerEmail,
}) => {
  const t = useT();
  const [countdown, setCountdown] = useState(
    hasInitialTimeout ? SECONDS_MAGIC_LINK_COOLDOWN : 0,
  );

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <div className="flex flex-col items-center gap-2">
      <Button
        className="min-w-[100px]"
        isDisabled={countdown > 0}
        key="graph.choices.SIGNUP.resend"
        text={t(
          countdown === 0
            ? { tx: "graph.choice.SIGNUP.resend" }
            : {
                tx: "graph.choice.SIGNUP.resendIn",
                txData: { time: countdown },
              },
        )}
        onClick={() => {
          reTriggerEmail();
          setCountdown(SECONDS_MAGIC_LINK_COOLDOWN);
        }}
      />
      <Text className="label-text py-2 font-bold" tx="graph.text.signup.info" />
    </div>
  );
};
