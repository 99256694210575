import { LoadingSpinner } from "./loading-spinner";
import { PlainI18nProps } from "./text";

export const LoadingScreen: React.FC<{
  message?: PlainI18nProps;
}> = ({ message = { tx: "general.loading" } }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <LoadingSpinner message={message} />
    </div>
  );
};
