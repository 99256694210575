import { FC, useCallback, useMemo, useState } from "react";

import { CommunityUser } from "../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { MemberCard } from "../components/community/member-card";
import { MemberProfile } from "../components/community/member-profile";
import { ProfileVisibilityCheckbox } from "../components/community/profile-visibility-checkbox";
import {
  applyQuery,
  CommunityMembersFilter,
} from "../components/community/query";
import { Button } from "../components/form/button";
import { TextInput } from "../components/form/text-input";
import { SvgIcon } from "../components/icons/svg-icon";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useModal } from "../models/modal-provider";
import { USER_DETAILS_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

export const CommunityMembers: FC = () => {
  const tenantId = useTenantId();
  const user = useUser();

  const { data: rawCommunityUsers } = useApiQuery(
    "backend",
    (client) => client.getAllCommunityUsers(toTenantIdHeader(tenantId)),
    USER_DETAILS_KEY,
  );

  const [communityMembersFilter, setCommunityMembersFilter] =
    useState<CommunityMembersFilter>({
      city: "",
      query: "",
      ageFrom: undefined,
      ageTo: undefined,
    });

  const isProfilePublic = useMemo(
    () => user?.settings?.agreedToBeingListed,
    [user?.settings?.agreedToBeingListed],
  );

  const communityUsers = useMemo(() => {
    return rawCommunityUsers
      ? applyQuery(rawCommunityUsers, communityMembersFilter)
      : undefined;
  }, [communityMembersFilter, rawCommunityUsers]);

  const applyFilters = useCallback((config: CommunityMembersFilter) => {
    setCommunityMembersFilter(config);
  }, []);

  return (
    <DefaultContentContainer>
      <CommunityMembersHeader isProfilePublic={isProfilePublic} />
      <CommunityMembersSearchFields
        isProfilePublic={isProfilePublic}
        applyFilters={applyFilters}
      />
      <CommunityMembersCards
        communityUsers={communityUsers}
        isProfilePublic={isProfilePublic}
      />
    </DefaultContentContainer>
  );
};

const CommunityMembersHeader: React.FC<{ isProfilePublic?: boolean }> = ({
  isProfilePublic,
}) => {
  return (
    <>
      <div className="hero">
        <div className="hero-content flex-col text-center lg:flex-row">
          <div>
            <Text
              as="h1"
              className="text-5xl font-bold"
              tx={
                isProfilePublic
                  ? "community.headline"
                  : "community.notAMember.headline"
              }
            />
            <Text
              as="p"
              className="py-6"
              tx={
                isProfilePublic
                  ? "community.description"
                  : "community.notAMember.description"
              }
            />
          </div>
        </div>
      </div>
      {!isProfilePublic && (
        <div className="flex w-full justify-center text-center">
          <ProfileVisibilityCheckbox />
        </div>
      )}
    </>
  );
};

const CommunityMembersSearchFields: React.FC<{
  isProfilePublic?: boolean;
  applyFilters: (config: CommunityMembersFilter) => void;
}> = ({ isProfilePublic, applyFilters }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchAge, setSearchAge] = useState<{
    min: number | null;
    max: number | null;
  }>({ min: null, max: null });

  return isProfilePublic ? (
    <div className="my-4 flex justify-end">
      <div className="flex gap-2">
        <div>
          <TextInput
            value={searchAge.min ?? ""}
            placeholder={{ tx: "filters.placeholders.ageFrom" }}
            type="number"
            onChange={(e) => {
              setSearchAge({
                min: parseInt(e.target.value),
                max: searchAge.max,
              });
            }}
          />
        </div>
        <div>
          <TextInput
            value={searchAge.max ?? ""}
            placeholder={{ tx: "filters.placeholders.ageTo" }}
            type="number"
            onChange={(e) => {
              setSearchAge({
                min: searchAge.min,
                max: parseInt(e.target.value),
              });
            }}
          />
        </div>
        <div>
          <TextInput
            placeholder={{ tx: "filters.placeholders.city" }}
            value={searchCity}
            type="text"
            onChange={(e) => {
              setSearchCity(e.target.value);
            }}
          />
        </div>
        <div className="relative">
          <TextInput
            placeholder={{ tx: "filters.search" }}
            className="pr-12"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <SvgIcon
            color="fill-base-300"
            className="absolute right-3 top-0 w-10 cursor-pointer"
            icon="cross"
            onClick={() => {
              setSearchQuery("");
            }}
          />
        </div>
        <Button
          type="submit"
          tx="filters.search"
          onClick={() => {
            applyFilters({
              city: searchCity,
              query: searchQuery,
              ageFrom: searchAge.min,
              ageTo: searchAge.max,
            });
          }}
        />
      </div>
    </div>
  ) : null;
};

const CommunityMembersCards: React.FC<{
  communityUsers?: CommunityUser[];
  isProfilePublic?: boolean;
}> = ({ isProfilePublic, communityUsers }) => {
  const { showModal } = useModal();

  const onMemeberCardClick = useCallback(
    (communityUser: CommunityUser) => {
      showModal({
        showsWindowCloseButton: true,
        closeOnClickOutside: true,
        showCloseButton: false,
        children: <MemberProfile communityUser={communityUser} />,
      });
    },
    [showModal],
  );

  return isProfilePublic ? (
    <>
      {communityUsers?.length === 0 && (
        <Text tx="community.noUsers" className="my-4 text-center" as="p" />
      )}
      <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
        {communityUsers?.map((communityUser) => (
          <MemberCard
            key={communityUser.email}
            data={communityUser}
            onClick={onMemeberCardClick}
          />
        ))}
      </div>
    </>
  ) : null;
};
