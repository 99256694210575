import { twMerge } from "tailwind-merge";

import { ModalConfig } from "../../models/modal-provider";
import { SecondaryButton } from "../form/button";
import { LoadingButton } from "../form/loading-button";
import { LoadingSpinner } from "../loading-spinner";
import { Text } from "../text";

export const Modal: React.FC<ModalConfig & { close: () => void }> = ({
  description,
  title,
  onConfirm,
  onClose,
  confirmButtonTx,
  closeButtonTx,
  children,
  loading,
  disabled,
  overflowVisible,
  closeOnClickOutside,
  showsWindowCloseButton,
  showCloseButton,
  close,
  confirmLoading,
  className,
}) => {
  return (
    <dialog
      open={true}
      className={twMerge("open modal z-[1002] overflow-auto backdrop-blur-sm")}
    >
      <div
        className={twMerge(
          "modal-box p-5",
          className,
          overflowVisible ? "overflow-visible" : "",
        )}
      >
        {showsWindowCloseButton && (
          <form method="dialog">
            <button
              className="btn-ghost btn btn-sm btn-circle absolute right-2 top-2"
              onClick={(e) => {
                e.preventDefault();
                close();
              }}
              disabled={disabled?.isCloseButtonDisabled}
            >
              {"✕"}
            </button>
          </form>
        )}
        <Text {...title} className="mb-3 text-lg font-bold" as="h3" />
        <Text className="py-4" {...description} />
        {loading?.isLoading ? (
          <LoadingSpinner className="justify-start" message={loading.message} />
        ) : (
          children
        )}

        {(showCloseButton !== false || onConfirm) && (
          <div className="modal-action">
            {showCloseButton !== false && (
              <SecondaryButton
                className="btn"
                tx={closeButtonTx ?? "general.close"}
                onClick={() => {
                  onClose?.();
                  close();
                }}
                isDisabled={disabled?.isCloseButtonDisabled}
              />
            )}
            {onConfirm && (
              <LoadingButton
                loading={confirmLoading ?? false}
                className="btn"
                tx={confirmButtonTx ?? "general.confirm"}
                onClick={onConfirm}
                isDisabled={disabled?.isConfirmButtonDisabled}
              />
            )}
          </div>
        )}
      </div>
      {closeOnClickOutside && (
        <form method="dialog" className="modal-backdrop">
          <button
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            <Text tx="general.close" />
          </button>
        </form>
      )}
    </dialog>
  );
};
