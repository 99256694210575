import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language, languageToBaseLanguageTable } from "shared/model/languages";
import { FrontendPageEnum } from "shared/model/pages";

import { useApiQuery } from "../api/use-api";
import { LoadingScreen } from "../components/loading-screen";
import { useGetPageInfo } from "../router/use-get-page-info";
import { SHARED_SESSION_TOKEN_FOR_FORUM_KEY } from "../types/query-keys";
import { deleteCookie, setCookie } from "../util/cookie";
import { forumUrl } from "../util/env-utils";
import { useTenantId } from "../util/use-active-tenant-id";

export const Forum: React.FC = () => {
  const tenantId = useTenantId();
  const getPageInfo = useGetPageInfo();

  const {
    i18n: { language },
  } = useTranslation();

  const {
    data: sharedSessionToken,
    isFetched,
    refetch,
  } = useApiQuery(
    "backend",
    (api) =>
      api.getSharedSessionTokenForForum({
        mamaDisease: tenantId.disease,
      }),
    SHARED_SESSION_TOKEN_FOR_FORUM_KEY(tenantId.disease),
  );

  const fullForumUrl = useMemo(() => {
    const category = getPageInfo({
      page: {
        route: FrontendPageEnum.FORUM,
        txPageName: "forum",
      },
      tenantId,
    }).forumCategory;

    const targetLanguage = languageToBaseLanguageTable[language as Language];

    return category
      ? `${forumUrl}/category/${category}?lang=${targetLanguage}`
      : `${forumUrl}?lang=${targetLanguage}`;
  }, [getPageInfo, language, tenantId]);

  useEffect(() => {
    refetch();
  }, [language, refetch]);

  useEffect(() => {
    if (sharedSessionToken) {
      setCookie({
        name: "forum-token",
        domain: `.${location.hostname}`,
        value: sharedSessionToken.token,
      });
    }
  }, [sharedSessionToken]);

  return isFetched && fullForumUrl ? (
    <iframe className="h-full w-full" src={fullForumUrl} allowFullScreen />
  ) : (
    <LoadingScreen />
  );
};

export const cleanUpForumToken = (): void => {
  deleteCookie({
    name: "forum-token",
    domain: `.${location.hostname}`,
  });
};
